// /* eslint-disable max-lines */
import { all, call, put } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import AuthSagas from '../../redux/auth/sagas'
import ApiSagas from '../../redux/api/sagas'
import { redirect } from '../../helpers/UrlHelpers'
import { Router } from '../../routes'
import { actions as appActions } from '../../redux/app/redux'

import { actions as pageActions } from './redux'
import * as services from './services'
import { transformSeo } from './transformers'

export default class PageSagas {
  static req = null
  static res = null

  static *init({ asPath, query, res, req }) {
    PageSagas.req = req
    PageSagas.res = res
    console.log({ asPath })
    if (
      asPath.match('/_next/*') ||
      asPath.match('/_webpack/*') ||
      asPath.match('/__webpack_hmr*')
    ) {
      //{
      return { error: 404 }
    }

    if (asPath === '/home' || asPath === '/') {
      return yield call(AuthSagas.redirectLogged)
    }

    return yield call(PageSagas.loadPage, query, asPath, res)
  }

  static *loadPage({ slug = '/' }) {
    yield put(showLoading())

    const result = yield call(ApiSagas.query, services.pageBySlug, {
      slug,
      localeFilters: { locale: 'en' },
    })

    if (result.data) {
      const seoParams = {
        title: result.data.title,
      }
      const seo = yield call(transformSeo, result.data, seoParams)

      yield all([
        put(pageActions.setData(result.data)),
        put(appActions.setSeo(seo)),
      ])
    }

    yield all([put(pageActions.setLoaded(true)), put(hideLoading())])

    if (!result.data) {
      return { error: 404 }
    }
  }

  static *redirectToPage(res = PageSagas.res, destinationRoute) {
    yield call(redirect, res, Router.getRouteUrl(destinationRoute), 303)
  }

  static *loop() {
    yield all([
      //
    ])
  }
}
