import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'
import MarkdownIt from 'markdown-it'

import withMemo from '../../decorators/withMemo'
import { useSafeCall } from '../../helpers/React'

import { configure, fixSpaces } from './utils'
import styles from './styles'


const useStyles = createUseStyles(styles)

const MarkdownText = (props) => {
  const classes = useStyles(props)
  const {
    className,
    text,
    tag,
    inline,
    autoBlankExternalLinks,
    onLinkClick,
    rendererProps,
    ...otherProps
  } = props


  const md = useMemo(
    () => configure(
      new MarkdownIt({
        breaks: true,
        html: true,
        ...rendererProps,
      }),
      { autoBlankExternalLinks }
    ),
    [rendererProps, autoBlankExternalLinks]
  )

  const correctedText = useMemo(() => fixSpaces(text), [text])
  const html = inline ? md.renderInline(correctedText) : md.render(correctedText)
  const Tag = tag || 'div'
  const ref = useRef(null)
  const handleClick = useSafeCall(onLinkClick)

  useEffect(() => {
    if (ref.current) {
      Array.from(ref.current.querySelectorAll('a')).forEach((m) => {
        // eslint-disable-next-line no-param-reassign
        m.onclick = handleClick
      })
    }
  }, [ref, html, handleClick])

  return (
    <Tag
      {...otherProps}
      ref={ref}
      className={cx(inline !== true && classes.container, className)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

MarkdownText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  tag: PropTypes.elementType,
  inline: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  rendererProps: PropTypes.object,
  autoBlankExternalLinks: PropTypes.bool,
  onLinkClick: PropTypes.func,
}

MarkdownText.defaultProps = {
  className: null,
  text: '',
  tag: 'div',
  inline: false,
  rendererProps: null,
  autoBlankExternalLinks: true,
  onLinkClick: () => null,
}

export default withMemo()(MarkdownText)
