import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StyleUtils'
import A from '../A'
import Icon from '../Icon'
import icons from '../Icon/icons'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Header = (props) => {
  const {
    classes: classesProp,
    className,
    texts,
    isLogged,
    homePageLinkProps,
    loginLinkProps,
    logoutLinkProps,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <header className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <A
          {...homePageLinkProps}
          className={classes.title}
        >
          <Icon
            icon={icons.Logo}
            alt={`${texts?.powered} ${texts?.title}`}
          />
        </A>
        <nav className={classes.login}>
          {isLogged ? (
            <A {...logoutLinkProps}>{texts?.logout}</A>
          ) : (
            <A {...loginLinkProps}>{texts?.login}</A>
          )}
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  texts: PropTypes.any,
  isLogged: PropTypes.any,
  homePageLinkProps: PropTypes.any,
  loginLinkProps: PropTypes.any,
  logoutLinkProps: PropTypes.any,
}

Header.defaultProps = {
  className: null,
  classes: null,
  texts: null,
  isLogged: null,
  homePageLinkProps: null,
  loginLinkProps: null,
  logoutLinkProps: null,
}

export default withMemo()(Header)
