import { defaultConfig } from '../../graphql/Client'

import * as queries from './queries'
import * as transformers from './transformers'


export const submit = {
  ...defaultConfig,
  query: queries.apply,
  transformer: transformers.submit,
}
