import { defaultConfig } from '../../graphql/Client'

import * as queries from './queries'
import * as transformers from './transformers'


export const register = {
  ...defaultConfig,
  query: queries.register,
  transformer: transformers.register,
}

