import { declareFont, defineFont, fontWeights } from '../utils/FontUtils'


export const declaration = {
  UniversRoman: {
    basename: 'UniversLTCYR-55Roman',
    fontFamily: 'UniversLTCYR-55Roman',
    fontWeight: fontWeights.regular,
    fallback: 'Arial, Helvetica, sans-serif',
  },
  // UniversCondensed: {
  //   basename: 'UniversLTCYR-57Condensed',
  //   fontFamily: 'UniversLTCYR-57Condensed',
  //   fontWeight: fontWeights.regular,
  // },
  UniversBoldCondensed: {
    basename: 'UniversLTCYR-67BoldCond',
    fontFamily: 'UniversLTCYR-67BoldCond',
    fontWeight: fontWeights.regular,
    fallback: 'Arial, Helvetica, sans-serif',
  },
}

export default {
  UniversRoman: defineFont(declaration.UniversRoman),
  // UniversCondensed: defineFont(declaration.UniversCondensed),
  UniversBoldCondensed: defineFont(declaration.UniversBoldCondensed),
}

export const fontsFaces = {
  '@font-face': Object.values(declaration).map(declareFont),
}
