import { pageJsonLd } from '../../helpers/JsonLdHelpers'
import Share from '../../data/models/Share'
import { app } from '../../configuration'
import { assetUrl } from '../../helpers/UrlHelpers'
import { getCanonicalURL } from '../../utils/URLUtils'


export const pageBySlug = (data) => data?.pageBySlug

export function transformJsonld(data, { title }) {
  if (data) {
    if (title) {
      return pageJsonLd({
        ...data,
        share: { ...data.seo, title },
        url: getCanonicalURL(
          `${app.APP_URL}${data.fullSlug}`
        ),
      })
    }
    return pageJsonLd({
      ...data,
      url: getCanonicalURL(
        `${app.APP_URL}${data.fullSlug}`
      ),
    })
  }

  return {}
}

export function transformSeo(data, params) {
  if (data) {
    const transformedShare = Share({ title: data.title, ...data.seo })

    return {
      title: data.title,
      ...transformedShare,
      ...params,
      url: getCanonicalURL(
        params.url || `${app.APP_URL}${data.fullSlug}`
      ),
      image: transformedShare.image ? assetUrl(transformedShare.image, { width: 1024 }) : '',
      noindex: false,
    }
  }

  return {}
}
