import * as PropTypes from 'prop-types'


const Share = (props) => props && {
  title: props.title || null,
  image: props.picture || props.image || null,
  description: props.description || null,
}

Share.propTypes = PropTypes.shape({
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
})

export default Share
