import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'

import LoadingBar from '../../components/LoadingBar'
import app from '../../configuration/app'
import withMemo from '../../decorators/withMemo'
import ErrorView from '../../pagesContents/Error'
import { actions as AppActions, selectors as AppSelectors } from '../../redux/app/redux'
import { actions as TrackingActions } from '../../redux/tracking/redux'
import { Router } from '../../routes'
import Metadatas from '../../components/Metadatas'

import styles from './styles'


const useStyles = createUseStyles(styles)

const AppGate = (props) => {
  // eslint-disable-next-line react/prop-types
  const { Component, pageContext, ...pageProps } = props

  const preview = useSelector(AppSelectors.preview)
  const seo = useSelector(AppSelectors.seo)
  const jsonld = useSelector(AppSelectors.jsonld)
  const alternates = useSelector(AppSelectors.alternates)


  const isError = pageProps.error
  const isErrorComp = pageProps.errorComponent

  const classes = useStyles(props)
  const dispatch = useDispatch()

  useEffect(() => {
    Router.onRouteChangeComplete = (url) => {
      dispatch(TrackingActions.trackPage(url))
      dispatch(AppActions.scrollTop(url))
    }

    dispatch(AppActions.initBrowser())
  }, [dispatch])

  return (
    <div className={classes.container}>
      <Metadatas
        applicationName="Balenciaga CV"
        seo={seo}
        alternates={alternates}
        base={preview === true ? app.APP_PREVIEW_URL : app.APP_URL}
        jsonld={jsonld || {}}
      />

      <LoadingBar />

      {isError && !isErrorComp && (
        <ErrorView
          pageContext={pageContext}
          {...pageProps}
        />
      )}

      {Component && (
        <>
          <Component
            pageContext={pageContext}
            {...pageProps}
          />
        </>
      )}
    </div>
  )
}

AppGate.propTypes = {
  pageProps: PropTypes.shape({
    error: PropTypes.number,
    errorComponent: PropTypes.any,
  }),
}

AppGate.defaultProps = {
  pageProps: {},
}

export default withMemo()(AppGate)
