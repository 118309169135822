import { gql } from 'apollo-boost'

import { UserFragment } from '../../graphql/fragments/UserFragment'


export const startApplication = gql`
  mutation startApplication {
    startApplication {
      ...UserFragment
    }
  }
  ${UserFragment}
`
