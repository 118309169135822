import { colors, fonts } from '../../theme'


export default {
  container: {
    position: 'sticky',
    top: '0',
    left: '0',
    right: '0',
    zIndex: '5',
    background: colors.pureWhite,
    '& > *': {
      borderBottom: `1px solid ${colors.pureBlack}`,
      padding: '1.2rem 2rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  title: {
    justifyContent: 'flex-start',
    margin: '0',
    ...fonts.UniversBoldCondensed,
    textAlign: 'center',
    textTransform: 'uppercase',
    '& > *': {
      display: 'block',
    },
    '& > * + *': {
      marginTop: '0.2rem',
    },
    '& > i': {
      fontStyle: 'normal',
      fontSize: '1.4rem',
    },
  },
  login: {
    ...fonts.UniversRoman,
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  '@media print': {
    login: {
      display: 'none',
    },
  },
}
