import { gql } from 'apollo-boost'


export const forgotPassword = gql`
  mutation forgotPassword($email: String!, $code: String!) {
    forgotPassword(email: $email, code: $code) {
      message
    }
  }
`
