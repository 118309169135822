import { gql } from 'apollo-boost'

import { UserFragment } from '../../graphql/fragments/UserFragment'


export const acceptDisclaimer = gql`
  mutation acceptDisclaimer {
    acceptDisclaimer {
      ...UserFragment
    }
  }
  ${UserFragment}
`
