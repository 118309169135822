/* eslint-disable react/no-danger */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'
import A from '../A'

import styles from './styles'



const useStyles = createUseStyles(styles)

const Footer = (props) => {
  const { classes: classesProp, className, links } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <footer className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <ul className={classes.contentLeft}>
          {links && links.map((link, index) => (
            <li key={index}><A {...link}>{link.text}</A></li>
          ))}
        </ul>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.shape(A.propTypes)),
}

Footer.defaultProps = {
  className: null,
  classes: {},
  links: null,
}

export default withMemo()(Footer)
