import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'
import Immutable from 'seamless-immutable'

import i18next from '../../i18n'
import ApiSagas from '../api/sagas'

import * as services from './services'
import { actions as TranslationActions, selectors as TranslationsSelectors } from './redux'


export default class TranslationSagas {

  static* init(lang) {
    const initLang = yield select(TranslationsSelectors.lang)

    if (!process.browser) {
      i18next.reset()
      yield put(TranslationActions.saveLang(lang, {}))
    }

    yield call(TranslationSagas.getLocales)
    yield put(TranslationActions.setLang((lang || initLang)))
    yield take(TranslationActions.saveLang.getType())
  }

  static* getLocales() {
    let locales = yield select(TranslationsSelectors.locales)


    if (!locales) {
      const result = yield call(ApiSagas.query, services.loadLocales)

      if (result.data) {
        locales = result.data.locales
      }
    }

    yield put(TranslationActions.setLocales(locales))
  }

  static* setLang({ payload }) {
    let { lang } = payload

    if (!i18next.locales[lang]) {
      lang = i18next.fallbackLng
    }

    yield call(TranslationSagas.loadLang, lang)

    i18next.changeLanguage(lang)

    yield all([
      put(TranslationActions.saveLang(lang, i18next.store.data)),
      call(ApiSagas.initHeaders, lang),
    ])
  }

  static* loadLang(lang) {
    const initialStore = Immutable.asMutable(yield select(TranslationsSelectors.store))

    let labels = initialStore[lang]

    if (!labels) {
      const result = yield call(ApiSagas.query, services.loadLabels, {
        localeFilters: { locale: lang },
      })

      if (!result.errors) {
        labels = result?.data?.labels
      } else {
        console.error('Translations not loaded successfully')
      }
    }

    const { store } = yield call(TranslationSagas.addLabels, lang, labels)

    i18next.addResourceBundle(lang, 'translation', store[lang].translation)
  }

  static* addLabels(lang, labels) {
    const configurationSelect = yield select(TranslationsSelectors.configuration)
    const configuration = configurationSelect ? Immutable.asMutable(configurationSelect) : {}
    const storeSelect = yield select(TranslationsSelectors.store)
    const store = storeSelect ? Immutable.asMutable(storeSelect) : {}

    const apiTranslations = {}
    const localeConfiguration = {}

    const labelTypes = {
      TEXT: 'text',
      UPLOAD: 'upload',
    }

    // eslint-disable-next-line no-unused-expressions
    labels?.forEach((label) => {
      if (label.type === labelTypes.TEXT) {
        apiTranslations[label.key] = label.value
      } else {
        localeConfiguration[label.key] = {
          type: label.type,
          value: label.value,
        }
      }
    })

    store[lang] = {
      translation: {
        ...i18next.locales[lang],
        ...apiTranslations,
      },
    }

    configuration[lang] = localeConfiguration

    yield put(TranslationActions.setStore(store))
    yield put(TranslationActions.setConfiguration(configuration))
    return { store, configuration }
  }

  static* loop() {
    yield all([takeLatest(TranslationActions.setLang.getType(), TranslationSagas.setLang)])
  }

}
