/* eslint-disable */
import Immutable from 'seamless-immutable'
import * as PropTypes from 'prop-types'

export const servicePropTypes = PropTypes.shape({
  pending: PropTypes.bool,
  success: PropTypes.bool,
  complete: PropTypes.bool,
  errors: PropTypes.any,
  data: PropTypes.any,
  params: PropTypes.any,
})

export const getServiceProps = () => ({
  pending: false,
  success: false,
  complete: false,
  errors: null,
  data: null,
  params: null,
})

export const reduceService = (id, state, params) =>
  Immutable.static.set(state,id, {
    pending: true,
    success: false,
    complete: false,
    errors: null,
    data: null,
    params,
  })

export const reduceServiceSuccess = (id, state, data) =>
  Immutable.static.set(state,id, {
    ...state[id],
    pending: false,
    success: true,
    complete: true,
    errors: null,
    data,
  })

export const reduceServiceError = (id, state, errors) =>
  Immutable.static.set(state,id, {
    ...state[id],
    pending: false,
    success: false,
    complete: true,
    data: null,
    errors,
  })

export const reduceServiceReset = (id, state) => Immutable.static.set(state,id, getServiceProps())
