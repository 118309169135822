const colors = {
  pureBlack: '#000',
  pureWhite: '#FFF',
  //
  lightGrey: '#F5F5F5',
  midGrey: '#808080',
  red: '#DC0B0B',
  //
  error: '#DC0B0B',
}

export default colors
