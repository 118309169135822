import Cookies from 'universal-cookie'
import URL from 'url'
import moment from 'moment/moment'

import app from '../configuration/app'

export const getCookiesObj = (req = null) => {
  if (req && req.universalCookies) {
    return req.universalCookies
  }

  return new Cookies()
}

export const setAuthCookie = (value, req = null, rememberMe = null) => {
  const cookies = getCookiesObj(!process.browser && req)

  let rememberMeValue = rememberMe

  if (rememberMe === null) {
    const rememberMeCookie = cookies.get('auth_token_remember_me')

    rememberMeValue =
      rememberMeCookie !== undefined &&
      (rememberMeCookie === 'true' || rememberMeCookie === true)
  }

  const expiresDate = rememberMeValue
    ? moment()
        .add(5, 'year')
        .toDate()
    : moment()
        .add(1, 'day')
        .toDate()
  console.log(app)

  cookies.set(app.COOKIE_NAME, value, {
    path: '/',
    domain: URL.parse(app.APP_URL).hostname,
    secure: app.SECURE_COOKIE,
    expires: expiresDate,
  })
  if (rememberMeValue) {
    cookies.set('auth_token_remember_me', true, {
      path: '/',
      domain: URL.parse(app.APP_URL).hostname,
      secure: app.SECURE_COOKIE,
      expires: expiresDate,
    })
  }
}

export const getAuthCookie = (req = null) => {
  const cookies = getCookiesObj(req)

  return cookies.get(app.COOKIE_NAME)
}

export const removeAuthCookie = (req = null) => {
  const cookies = getCookiesObj(!process.browser && req)

  cookies.remove(app.COOKIE_NAME, {
    path: '/',
    domain: URL.parse(process.env.NEXT_PUBLIC_APP_URL).hostname,
    secure: process.env.SECURE_COOKIE === 'true',
  })
  cookies.remove('auth_token_remember_me', {
    path: '/',
    domain: URL.parse(process.env.NEXT_PUBLIC_APP_URL).hostname,
    secure: process.env.SECURE_COOKIE === 'true',
  })
}

export const initCookie = (resetCookie = false, req = null) => {
  if (resetCookie) {
    removeAuthCookie(req)
  }
}

export const setAfterAuthRedirectCookie = (value, req = null) => {
  const cookies = getCookiesObj(req)

  const expiresDate = moment()
    .add(1, 'day')
    .toDate()

  cookies.set('after_auth_redirection', value, {
    path: '/',
    domain: URL.parse(process.env.NEXT_PUBLIC_APP_URL).hostname,
    secure: process.env.SECURE_COOKIE === 'true',
    expires: expiresDate,
  })
}

export const getAfterAuthRedirectCookie = (req = null) => {
  const cookies = getCookiesObj(req)

  return cookies.get('after_auth_redirection')
}

export const removeAfterAuthRedirectCookie = (req = null) => {
  const cookies = getCookiesObj(req)

  cookies.remove('after_auth_redirection', {
    path: '/',
    domain: URL.parse(process.env.NEXT_PUBLIC_APP_URL).hostname,
    secure: process.env.SECURE_COOKIE === 'true',
  })
}
