import { transition } from '../../theme'


export default {
  container: {
    display: 'inline-flex',
    position: 'relative',
    '& *[fill]:not([fill="none"]):not(.no-fill)': {
      fill: (props) => props.color,
    },
    '& *[stroke]:not([stroke="none"]):not(.no-stroke)': {
      stroke: (props) => props.color,
    },
    '& *[fill]:not([fill="none"]):not(.no-fill), & *[stroke]:not([stroke="none"]):not(.no-stroke)': {
      ...transition(['fill', 'stroke']),
    },
    '& a': {
      display: 'flex',
    },
  },
  icon: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
}
