/* eslint-disable camelcase */
/* eslint-disable complexity */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

import withMemo from '../../decorators/withMemo'
import { app } from '../../configuration'
import { colors } from '../../theme'
import JsonLd from '../JsonLd'
import { declaration as fontDeclarations } from '../../theme/fonts'


const Metadatas = (props) => {
  const { applicationName, seo, base, jsonld, alternates } = props

  const preloadFonts = useMemo(
    () => Object.values(fontDeclarations).map((f, k) => (
      <link
        rel="preload"
        href={`/fonts/${f.basename}/${f.basename}.woff2`}
        as="font"
        type="font/woff2"
        key={k}
        crossOrigin="anonymous"
      />
    )),
    []
  )

  return (
    <Head>
      <meta charSet="utf-8" />
      {seo?.title && <title>{seo.title}</title>}
      {seo?.title && (
        <meta
          property="og:title"
          content={seo.title}
        />
      )}
      {seo?.description && (
        <meta
          name="description"
          content={seo.description}
        />
      )}
      {seo?.description && (
        <meta
          property="og:description"
          content={seo.description}
        />
      )}
      {seo?.type && (
        <meta
          property="og:type"
          content={seo.type}
        />
      )}
      {seo?.url && (
        <meta
          property="og:url"
          content={seo.url}
        />
      )}
      <meta
        property="og:locale"
        content="fr_FR"
      />
      {seo?.title && (
        <meta
          name="twitter:title"
          content={seo.title}
        />
      )}
      {seo?.description && (
        <meta
          name="twitter:description"
          content={seo.description}
        />
      )}
      {seo?.twitter_card && (
        <meta
          name="twitter:card"
          content={seo.twitter_card}
        />
      )}
      {seo?.image && (
        <>
          <meta
            property="og:image"
            content={seo.image}
          />
          <meta
            name="twitter:image"
            content={seo.image}
          />
        </>
      )}
      {seo?.twitter_account && (
        <>
          <meta
            property="twitter:site"
            content={seo.twitter_account}
          />
          <meta
            name="twitter:creator"
            content={seo.twitter_account}
          />
        </>
      )}
      {seo?.url && (
        <link
          rel="canonical"
          href={seo.url}
        />
      )}
      {seo?.noindex || app.NO_INDEX ? (
        <meta
          name="robots"
          content="noindex, nofollow"
        />
      ) : (
        <>
          <meta
            name="robots"
            content="index, follow"
          />
          <meta
            name="googlebot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
        </>
        )}
      <base href={base} />
      <meta
        name="screen-orientation"
        content="portrait"
      />
      <meta
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5"
        name="viewport"
      />
      {jsonld && <JsonLd data={jsonld} />}
      <link
        rel="preconnect"
        href="https://res.cloudinary.com"
        crossOrigin="anonymous"
      />
      <link
        rel="preconnect"
        href="https://sessions.bugsnag.com"
        crossOrigin="anonymous"
      />
      {preloadFonts}
      {/* Favicon */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link
        rel="manifest"
        href="/site.webmanifest"
      />
      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg"
        color={colors.seaBlue}
      />
      <meta
        name="apple-mobile-web-app-title"
        content="Nice Port Center"
      />
      <meta
        name="application-name"
        content="Nice Port Center"
      />
      <meta
        name="msapplication-TileColor"
        content={colors.seaBlue}
      />
      <meta
        name="theme-color"
        content="#ffffff"
      />
      {/* Android */}
      <meta
        name="theme-color"
        content={colors.seaBlue}
      />
      <meta
        name="mobile-web-app-capable"
        content="yes"
      />
      {/* iOS */}
      <meta
        name="apple-mobile-web-app-title"
        content={applicationName}
      />
      <meta
        name="apple-mobile-web-app-capable"
        content="yes"
      />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="default"
      />

      {/* Windows */}
      <meta
        name="msapplication-config"
        content="browserconfig.xml"
      />

      {/* Pinned Sites */}
      <meta
        name="application-name"
        content={applicationName}
      />
      <meta
        name="msapplication-tooltip"
        content={applicationName}
      />
      <meta
        name="msapplication-starturl"
        content="/fr"
      />

      {/* Tap highlighting */}
      <meta
        name="msapplication-tap-highlight"
        content="no"
      />

      {/* UC Mobile Browser */}
      <meta
        name="full-screen"
        content="yes"
      />
      <meta
        name="browsermode"
        content="application"
      />

      {/* Disable night mode for this page */}
      <meta
        name="nightmode"
        content="enable"
      />

      {/* Layout mode */}
      <meta
        name="layoutmode"
        content="fitscreen"
      />

      {/* imagemode - show image even in text only mode */}
      <meta
        name="imagemode"
        content="force"
      />
      {alternates && alternates.map((alternate) => (
        <link
          rel="alternate"
          href={alternate.url}
          hrefLang={alternate.iso}
          key={`alternates-${alternate.iso}`}
        />
      ))}
    </Head>
  )
}

Metadatas.propTypes = {
  applicationName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  seo: PropTypes.object,
  base: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  jsonld: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  alternates: PropTypes.array,
}

Metadatas.defaultProps = {
  applicationName: null,
  seo: null,
  base: null,
  jsonld: null,
  alternates: null,
}

export default withMemo()(Metadatas)
