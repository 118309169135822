/* eslint-disable max-lines */
import { all, call, put } from 'redux-saga/effects'

import routes, { Router } from '../../routes'
import i18n from '../../i18n'
import { app } from '../../configuration'
import { actions as appActions } from '../../redux/app/redux'


export default class SubmitSchoolSagas {

  static* init() {
    return yield call(SubmitSchoolSagas.setupSeo)
  }

  static* setupSeo() {
    yield put(
      appActions.setSeo({
        title: i18n.t('SEO_submitSchool_title'),
        description: i18n.t('SEO_submitSchool_description'),
        keywords: i18n.t('SEO_submitSchool_keywords'),
        url: `${app.APP_URL}${Router.getRouteUrl(routes.submitSchool)}`,
        noindex: false,
      })
    )
  }


  static* loop() {
    yield all([
      //
    ])
  }

}
