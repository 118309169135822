import { createAction, createReducer } from 'redux-act'

import { GraphqlErrors } from '../../helpers/GraphqlHelpers'
import {
  getServiceProps,
  reduceService,
  reduceServiceError,
  reduceServiceReset,
  reduceServiceSuccess
} from '../../helpers/ReduxHelpers'


export const key = 'Disclaimer'

//
// Initial state
//
const initialState = {
  acceptDisclaimer: getServiceProps(),
}

//
// Actions
//
export const actions = {
  acceptDisclaimer: createAction(`${key}: acceptDisclaimer`, (params) => params),
  acceptDisclaimerSuccess: createAction(`${key}: acceptDisclaimerSuccess`, (data) => data),
  acceptDisclaimerError: createAction(`${key}: acceptDisclaimerError`, (errors) => errors),
  acceptDisclaimerReset: createAction(`${key}: acceptDisclaimerReset`),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.acceptDisclaimer]: (state, params) => reduceService('acceptDisclaimer', state, params),
    [actions.acceptDisclaimerSuccess]: (state, data) => reduceServiceSuccess('acceptDisclaimer', state, data),
    [actions.acceptDisclaimerError]: (state, error) => reduceServiceError('acceptDisclaimer', state, GraphqlErrors(error)),
    [actions.acceptDisclaimerReset]: (state) => reduceServiceReset('acceptDisclaimer', state),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const acceptDisclaimer = (state) => root(state).acceptDisclaimer

export const selectors = {
  acceptDisclaimer,
}
