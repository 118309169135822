import { defaultConfig } from '../../graphql/Client'

import * as queries from './queries'
import * as transformers from './transformers'


export const me = {
  ...defaultConfig,
  query: queries.me,
  transformer: transformers.me,
}

export const updateCv = {
  ...defaultConfig,
  query: queries.updateCv,
}

export const updateFiles = {
  ...defaultConfig,
  query: queries.updateFiles,
}

export const logout = {
  ...defaultConfig,
  query: queries.logout,
}
