import { gql } from 'apollo-boost'

import { UserFragment } from '../../graphql/fragments/UserFragment'


export const login = gql`
  mutation login($code: String!, $email: String!, $password: String!) {
    login(code: $code, email: $email, password: $password) {
      token_type
      expires_in
      access_token
      refresh_token
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`

