/* eslint-disable prefer-template */
const routes = {
  // Auth routes
  start: {
    component: '/start',
    path: '/start',
    name: 'start',
    auth: true,
  },
  thankyou: {
    component: '/thankyou',
    path: '/thankyou',
    name: 'thankyou',
    auth: true,
  },
  applicationSubmit: {
    component: '/application-submit',
    path: '/application-submit',
    name: 'applicationSubmit',
    auth: true,
  },
  applicationPortfolio: {
    component: '/application-portfolio',
    path: '/application-moodboard',
    name: 'applicationPortfolio',
    auth: true,
  },
  applicationPersonal: {
    component: '/application-personal',
    path: '/application-personal',
    name: 'applicationPersonal',
    auth: true,
  },
  applicationBackground: {
    component: '/application-background',
    path: '/application-background',
    name: 'applicationBackground',
    auth: true,
  },
  applicationCv: {
    component: '/application-cv',
    path: '/application-cv',
    name: 'applicationCv',
    auth: true,
  },
  disclaimer: {
    component: '/disclaimer',
    path: '/disclaimer',
    name: 'disclaimer',
    auth: true,
  },
  //
  printCandidacy: {
    component: '/candidacy/[id]',
    path: '/candidacy/:id',
    name: 'printCandidacy',
  },
  homepage: {
    component: '/[...slug]',
    path: '/home',
    name: 'homepage',
  },
  resetPassword: {
    component: '/reset-password',
    path: '/reset-password',
    name: 'resetPassword',
  },
  forgotPassword: {
    component: '/forgot-password',
    path: '/forgot-password',
    name: 'forgotPassword',
  },
  logout: {
    component: '/logout',
    path: '/logout',
    name: 'logout',
  },
  login: {
    component: '/login',
    path: '/login',
    name: 'login',
  },
  submitSchool: {
    component: '/submit-your-school',
    path: '/submit-your-school',
    name: 'submitSchool',
  },
  about: {
    component: '/about',
    path: '/about',
    name: 'about',
  },
  scholarship: {
    component: '/scholarship',
    path: '/scholarship',
    name: 'scholarship',
  },
  accueil: {
    component: '/[...slug]',
    path: '/',
    name: 'accueil',
  },
  //
  // Page Wildcards (must be last)
  //
  page: {
    component: '/[...slug]',
    path: '/:slug+',
    name: 'page',
  },
}

module.exports = routes
