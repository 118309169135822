import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const AccessibleHiddenText = (props) => {
  const { classes: classesProp, className, text } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  if (!text) {
    return
  }

  return (
    <span className={cx(classes.container, className)}>{text}</span>
  )
}

AccessibleHiddenText.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
}

AccessibleHiddenText.defaultProps = {
  className: null,
  classes: {},
  text: '',
}

export default withMemo()(AccessibleHiddenText)
