import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Router } from '../routes'
import routes from '../routes/definitions'
import { selectors as AuthSelectors } from '../redux/auth/redux'

export const useHeader = () => {
  const isConnected = useSelector(AuthSelectors.isConnected)

  return useMemo(
    () => ({
      texts: {
        title: 'Balenciaga',
        powered: 'Powered By',
        login: 'Log In',
        logout: 'Log Out',
      },
      homePageLinkProps: {
        href: Router.getRouteUrl(routes.scholarship),
      },
      loginLinkProps: {
        href: Router.getRouteUrl(routes.login),
      },
      logoutLinkProps: {
        href: Router.getRouteUrl(routes.logout),
      },
      isLogged: isConnected,
    }),
    [isConnected]
  )
}

export const useFooter = () => ({
  links: [
    {
      text: 'Privacy policy',
      href: Router.getRouteUrl(routes.page, { slug: 'privacy-policy' }),
      target: '_blank',
    },
    {
      text: 'Intellectual Property Policy',
      href: Router.getRouteUrl(routes.page, {
        slug: 'intellectual-property-policy',
      }),
      target: '_blank',
    },
    {
      text: `© ${new Date().getFullYear()} Balenciaga`,
      href: 'http://www.balenciaga.com/',
      target: '_blank',
    },
  ],
})
