import { gql } from 'apollo-boost'


export const refreshToken = gql`
  mutation refreshToken($token: String!) {
    refreshToken(refresh_token: $token) {
      token_type
      expires_in
      access_token
      refresh_token
    }
  }
`
