import { gql } from 'apollo-boost'


export const pageBySlug = gql`
  query pageBySlug($slug: String!) {
    pageBySlug(slug: $slug) {
      content
      title
    }
  }
`
