const sizes = {
  phone: 576,
  tablet: 768,
  desktop: 992,
  fullDesktop: 1160,
  maxDesktop: 1440,
}

export default sizes

// Media Queries

export const tabletMedia = `(min-width: ${sizes.tablet}px)`
export const tabletMediaQuery = `@media ${tabletMedia}`

export const desktopMedia = `(min-width: ${sizes.desktop}px)`
export const desktopMediaQuery = `@media ${desktopMedia}`

export const fullDesktopMedia = `(min-width: ${sizes.fullDesktop}px)`
export const fullDesktopMediaQuery = `@media ${fullDesktopMedia}`

export const maxDesktopMedia = `(min-width: ${sizes.maxDesktop}px)`
export const maxDesktopMediaQuery = `@media ${maxDesktopMedia}`
