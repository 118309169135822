import { jss, SheetsRegistry } from 'react-jss'

import { app } from '../configuration'


export const jssInstance = jss

function createPageContext() {
  const maxRules = 1e10

  function createGenerateId(options) {
    let ruleCounter = 0

    return (rule) => {
      ruleCounter += 1

      if (ruleCounter > maxRules) {
        throw new Error(`[JSS] You might have a memory leak. Rule counter is at ${ruleCounter}.`)
      }

      if (app.APP_ENV !== 'local' || options.minify) {
        return `c-${ruleCounter}`
      }

      return `${rule.key}-${ruleCounter}`
    }
  }

  // custom Jss config
  jssInstance.setup({
    createGenerateId,
  })

  // custom JssProvider props

  return {
    theme: {},
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateId,
    // The jss instance
    jss: jssInstance,
    generateId: jssInstance.generateId,
  }
}

let pageContext

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    pageContext = createPageContext()
  }

  // Reuse context on the client-side.
  if (!pageContext) {
    pageContext = createPageContext()
  }

  return pageContext
}
