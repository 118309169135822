import { all, fork } from 'redux-saga/effects'


const entities = [
  // Pages
  require('../pagesContents/Page/sagas').default,
  require('../pagesContents/Login/sagas').default,
  require('../pagesContents/About/sagas').default,
  require('../pagesContents/Scholarship/sagas').default,
  require('../pagesContents/SubmitSchool/sagas').default,
  require('../pagesContents/Disclaimer/sagas').default,
  require('../pagesContents/Start/sagas').default,
  require('../pagesContents/ApplicationCv/sagas').default,
  require('../pagesContents/ApplicationBackground/sagas').default,
  require('../pagesContents/ApplicationPersonal/sagas').default,
  require('../pagesContents/ApplicationSubmit/sagas').default,
  require('../pagesContents/Thankyou/sagas').default,
  require('../pagesContents/ForgotPassword/sagas').default,
  require('../pagesContents/ResetPassword/sagas').default,
  require('../pagesContents/PrintCandidacy/sagas').default,

  // Global
  require('./api/sagas').default,
  require('./app/sagas').default,
  require('./auth/sagas').default,
  require('./i18n/sagas').default,
]


function* loop() {
  yield all(entities.map((entity) => entity.loop()))
}

export default function* rootSaga() {
  yield fork(loop)
}
