import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFooter, useHeader } from '../../hooks/useAppLayout'
import ErrorTemplate from '../../templates/Error'


const Error = (props) => {
  const { t } = useTranslation()
  const headerProps = useHeader()
  const footerProps = useFooter()
  const layoutProps = {
    theme: {
      maxWidth: 640,
      contentPosition: 'middle',
    },
  }
  const feedbackProps = {
    text: t('404_message'),
    actions: [
      {
        link: '/',
        label: t('back_to_home'),
      },
    ],
  }

  const errorTemplateProps = {
    headerProps,
    footerProps,
    layoutProps,
    feedbackProps,
    ...props,
  }

  return (<ErrorTemplate {...errorTemplateProps} />)
}

Error.propTypes = {}

Error.defaultProps = {}

export default Error
