import fonts from './fonts'


const textStyles = {
  lorem: {
    ...fonts.UniversRoman,
  },
  h1: {
    ...fonts.UniversBoldCondensed,
    fontSize: '1.7rem',
    lineHeight: '1.1666',
    textTransform: 'uppercase',
  },
  h2Caps: {
    ...fonts.UniversRoman,
    fontSize: '1.2rem',
    lineHeight: '1.1666',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
  h3: {
    ...fonts.UniversBoldCondensed,
    fontSize: '1.2rem',
    lineHeight: '1.1666',
    textTransform: 'uppercase',
  },
  text: {
    ...fonts.UniversRoman,
    fontSize: '1.2rem',
    lineHeight: '1.5',
    letterSpacing: '0.02em',
  },
  label: {
    ...fonts.UniversRoman,
    fontSize: '1.2rem',
    lineHeight: '1.142',
    letterSpacing: '0.02em',
  },
  tiny: {
    ...fonts.UniversRoman,
    fontSize: '1.2rem',
    lineHeight: '1.5',
    letterSpacing: '0.02em',
  },
}

export default textStyles
