import { textStyles } from '../../theme'


export default {
  container: {
    '&.is-textinform': {
      ...textStyles.text,
    },
    '&.is-titleinform': {
      ...textStyles.h3,
    },
    '&.is-noteinform': {
      ...textStyles.text,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
}
