import { createAction, createReducer } from 'redux-act'

import {
  getServiceProps,
  reduceService,
  reduceServiceError,
  reduceServiceReset,
  reduceServiceSuccess
} from '../../helpers/ReduxHelpers'


export const key = 'ApplicationSubmit'

//
// Initial state
//
const initialState = {
  submit: getServiceProps(),
}

//
// Actions
//
export const actions = {
  submit: createAction(`${key}: submit`, (params) => params),
  submitSuccess: createAction(`${key}: submitSuccess`, (data) => data),
  submitError: createAction(`${key}: submitError`, (errors) => errors),
  submitReset: createAction(`${key}: submitReset`),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.submit]: (state, params) => reduceService('submit', state, params),
    [actions.submitSuccess]: (state, data) => reduceServiceSuccess('submit', state, data),
    [actions.submitError]: (state, error) => reduceServiceError('submit', state, error),
    [actions.submitReset]: (state) => reduceServiceReset('submit', state),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const submit = (state) => root(state).submit

export const selectors = {
  submit,
}
