import colors from './colors'
import textStyles from './textStyles'


export const formErrorsStyles = () => ({
  display: 'flex',
  justifyContent: 'center',
  ...textStyles.text,
  color: colors.error,
})
