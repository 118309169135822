import { tablet } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    minHeight: '100vh',
    '& > *': {
      flex: '0 0 auto',
      width: '100%',
    },
  },
  main: {
    paddingBottom: '3rem',
    paddingTop: (props) => (props?.theme?.contentPosition === 'middle' ? '3rem' : '0'),
    marginTop: (props) => (props?.theme?.contentPosition === 'middle' ? 'auto' : '0'),
    marginBottom: 'auto',
    '& > *': {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    // vertical spacing
    '& > * + *': {
      marginTop: '2.5rem',
    },
    '& > *:first-child': {
      marginTop: '3rem',
    },
    '& > $nav + $title': {
      marginTop: '5rem',
    },
    '& > $title:first-child': {
      marginTop: '3rem',
    },
    '& > $nav:first-child': {
      marginTop: '1rem',
    },
    ...tablet({
      paddingBottom: '6rem',
      paddingTop: (props) => (props?.theme?.contentPosition === 'middle' ? '6rem' : '3rem'),
    }),
  },
  nav: {},
  title: {},
}
