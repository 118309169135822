import { createReducer } from 'redux-act'



export const key = 'Thankyou'

//
// Initial state
//
const initialState = {}

//
// Actions
//
export const actions = {}

//
// Reducer
//
export const reducer = createReducer(
  {},
  initialState
)

//
// Selectors
//
// const root = (state) => state[key]

export const selectors = {}
