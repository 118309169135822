import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { ThemeProvider } from 'theming'

import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'

import styles from './styles'

const useStyles = createUseStyles(styles)

const LayoutDefault = props => {
  const {
    classes: classesProp,
    className,
    header,
    nav,
    title,
    content,
    footer,
    theme,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [
    classesProp,
    classesComp,
  ])
  return (
    <ThemeProvider theme={theme}>
      <div
        className={cx(
          classes.container,
          className,
          theme?.contentPosition === 'middle' && classes.containerCentered
        )}
      >
        <>{header}</>
        <main className={classes.main}>
          {nav && <div className={classes.nav}>{nav}</div>}
          {title && <div className={classes.title}>{title}</div>}
          {content}
        </main>
        <>{footer}</>
      </div>
    </ThemeProvider>
  )
}

LayoutDefault.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  header: PropTypes.node.isRequired,
  nav: PropTypes.node,
  title: PropTypes.node,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
}

LayoutDefault.defaultProps = {
  className: null,
  classes: {},
  nav: null,
  title: null,
  theme: null,
}

export default withMemo()(LayoutDefault)
