import { defaultConfig } from '../../graphql/Client'

import * as queries from './queries'
import * as transformers from './transformers'


export const candidateById = {
  ...defaultConfig,
  query: queries.candidateById,
  transformer: transformers.candidateById,
}

