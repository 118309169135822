import { createReducer } from 'redux-act'
import { createSelector } from 'reselect'
import Immutable from 'seamless-immutable'

import Token from '../../data/models/Token'
import { createActions } from '../../helpers/Redux'


export const key = 'auth'

//
// Initial state
//
const initialState = {
  user: null,
  token: null,
  printToken: null,
}

//
// Actions
//
export const actions = createActions(key, {
  setUser: (user) => user,
  setToken: (token) => Token(token),
  setPrintToken: (printToken) => printToken,
  setTokenValid: (valid) => valid,

  updateCv: (cv, fields) => ({ cv, fields }),
  updateFiles: (files) => files,
  loadMe: null,
  // Logout
  logout: null,
})

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setUser]: (state, user) => Immutable.merge(state, { user }),
    [actions.setToken]: (state, token) => Immutable.merge(state, { token }),
    [actions.setPrintToken]: (state, printToken) => Immutable.merge(state, { printToken }),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const user = (state) => root(state).user
const token = (state) => root(state).token
const printToken = (state) => root(state).printToken

const isConnected = createSelector([user], (value) => Boolean(value?.id))

export const selectors = {
  user,
  token,
  printToken,
  isConnected,
}
