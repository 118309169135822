export const fontWeights = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
}

export const fontStyles = {
  normal: 'normal',
  italic: 'italic',
  oblique: 'oblique',
}

export const defineFont = (font) => ({
  fontFamily: `"${font.fontFamily}", ${font.fallback ?? 'sans-serif'}`,
  fontWeight: font?.fontWeight || fontWeights.regular,
  fontStyle: font?.fontStyle || fontStyles.normal,
})

export const declareFont = ({ name, basename, ...params }) => {
  const path = `/fonts/${basename}/${basename}`

  return {
    ...params,
    fontDisplay: 'swap',
    src: [
      `local(${basename})`,
      `url("${path}.woff2") format("woff2")`,
      `url("${path}.woff") format("woff")`,
    ],
  }
}
