import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import { GraphqlErrors } from '../../helpers/GraphqlHelpers'
import {
  getServiceProps,
  reduceService,
  reduceServiceError,
  reduceServiceReset,
  reduceServiceSuccess
} from '../../helpers/ReduxHelpers'


export const key = 'ForgotPassword'

//
// Initial state
//
const initialState = {
  forgotPassword: getServiceProps(),
  message: null,
}

//
// Actions
//
export const actions = {
  forgotPassword: createAction(`${key}: forgotPassword`, (params) => params),
  forgotPasswordSuccess: createAction(`${key}: forgotPasswordSuccess`, (data) => data),
  forgotPasswordError: createAction(`${key}: forgotPasswordError`, (errors) => errors),
  forgotPasswordReset: createAction(`${key}: forgotPasswordReset`),
  setMessage: createAction('ForgotPassword: setMessage', (message) => ({ message })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.forgotPassword]: (state, params) => reduceService('forgotPassword', state, params),
  [actions.forgotPasswordSuccess]: (state, data) => reduceServiceSuccess('forgotPassword', state, data),
  [actions.forgotPasswordError]: (state, error) => reduceServiceError('forgotPassword', state, GraphqlErrors(error)),
  [actions.forgotPasswordReset]: (state) => reduceServiceReset('forgotPassword', state),
  [actions.setMessage]: (state, { message }) => Immutable.static.merge(state, { message }),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const forgotPassword = (state) => root(state).forgotPassword
const message = (state) => root(state).message

export const selectors = {
  forgotPassword,
  message,
}
