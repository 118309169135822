/*
 * Width values are in px, to use them on styles convert them in rem by / 10
 */

export default {
  mobile: {
    sideGutterWidth: 20,
  },
  tablet: {},
  desktop: {
    box: 2000,
  },
}
