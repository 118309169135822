import { defaultConfig } from '../../graphql/Client'

import * as queries from './queries'
import * as transformers from './transformers'


export const loadLocales = {
  ...defaultConfig,
  query: queries.loadLocales,
  transformer: transformers.loadLocales,
}

export const loadLabels = {
  ...defaultConfig,
  query: queries.loadLabels,
  transformer: transformers.loadLabels,
}
