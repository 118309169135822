import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { withTheme } from 'theming'

import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'
import ActionButton from '../ActionButton'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Feedback = (props) => {
  const { classes: classesProp, className, text, actions } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {text && (
          <MarkdownText
            className={classes.text}
            text={text}
          />
        )}
        {actions && (
          <div className={classes.actions}>
            {actions.map((action, index) => (
              <ActionButton
                key={index}
                {...action}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

Feedback.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.objectOf(PropTypes.string),
  actions: PropTypes.arrayOf(PropTypes.shape(ActionButton.propTypes)),
}

Feedback.defaultProps = {
  className: null,
  classes: {},
  text: '',
  actions: null,
}

export default withMemo()(withTheme(Feedback))
