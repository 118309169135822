export default {
  loaderContainer: {
    position: 'fixed',
    width: '100%',
    margin: '0 auto',
    height: '0.3rem',
    zIndex: '999',
    top: '0',
    overflow: 'hidden',
  },
  loader: {
    position: 'absolute',
    height: '0.3rem',
    width: '100%',
    backgroundColor: 'red',
  },
}
