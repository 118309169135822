import { all, call, put } from 'redux-saga/effects'

import TranslationSagas from '../i18n/sagas'
import AuthSagas from '../auth/sagas'

import { actions } from './redux'



export default class AppSagas {

  static* init(ctx, pageSaga) {
    const { pathname, query, asPath } = ctx

    yield call(AppSagas.updateRoute, {
      pathname,
      query,
      asPath,
    })

    yield call(AuthSagas.init, ctx)

    if (!process.browser) {
      yield all([
        call(AppSagas.loadInitialData),
        call(TranslationSagas.init, query.lang || 'en'),
      ])
    }

    // clear JsonLd
    yield put(actions.setJsonld(null))

    const initialProps = pageSaga ? yield call(pageSaga, ctx) : {}

    if (ctx.res && ctx.res.statusCode > 400) {
      ctx.res.statusCode = 404
    }

    if (ctx.res && initialProps && initialProps.error) {
      ctx.res.statusCode = initialProps.error
    }

    return initialProps || { loaded: true }
  }

  static* loadInitialData() {
    return yield call(AppSagas.requestInitialData)
  }

  static* requestInitialData() {
    //
  }

  static* updateRoute(route) {
    yield put(actions.setRoute(route))
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
