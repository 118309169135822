import { createAction, createReducer } from 'redux-act'

import { GraphqlErrors } from '../../helpers/GraphqlHelpers'
import {
  getServiceProps,
  reduceService,
  reduceServiceError,
  reduceServiceReset,
  reduceServiceSuccess
} from '../../helpers/ReduxHelpers'


export const key = 'Scholarship'

//
// Initial state
//
const initialState = {
  register: getServiceProps(),
}

//
// Actions
//
export const actions = {
  register: createAction(`${key}: register`, (params) => params),
  registerSuccess: createAction(`${key}: registerSuccess`, (data) => data),
  registerError: createAction(`${key}: registerError`, (errors) => errors),
  registerReset: createAction(`${key}: registerReset`),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.register]: (state, params) => reduceService('register', state, params),
    [actions.registerSuccess]: (state, data) => reduceServiceSuccess('register', state, data),
    [actions.registerError]: (state, error) => reduceServiceError('register', state, GraphqlErrors(error)),
    [actions.registerReset]: (state) => reduceServiceReset('register', state),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const register = (state) => root(state).register

export const selectors = {
  register,
}
