import { createAction, createReducer } from 'redux-act'
import { createSelector } from 'reselect'
import Immutable from 'seamless-immutable'

import { selectors as AuthSelectors } from '../auth/redux'


export const key = 'api'

//
// Initial state
//
const initialState = {
  headers: {},
}

//
// Actions
//
export const actions = {
  setHeaders: createAction('App: setHeaders', (headers) => headers),
}

export const reducer = createReducer(
  {
    [actions.setHeaders]: (state, headers) => Immutable.static.merge(state, { headers }),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state.api
const getHeaders = (state) => root(state).headers

const getEnhancedHeaders = createSelector([getHeaders, AuthSelectors.token], (headers, token) => ({
  ...headers,
  ...(token?.access_token && {
    Authorization: `Bearer ${token.access_token}`,
  }),
}))

export const selectors = {
  headers: getEnhancedHeaders,
}
