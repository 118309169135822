import { defaultConfig } from '../../graphql/Client'

import * as queries from './queries'
import * as transformers from './transformers'


export const forgotPassword = {
  ...defaultConfig,
  query: queries.forgotPassword,
  transformer: transformers.forgotPassword,
}

