import { /* createAction, */ createReducer } from 'redux-act'
// import Immutable from 'seamless-immutable'
// import moment from 'moment'


export const key = 'SubmitSchool'


//
// Initial state
//
const initialState = {}

//
// Actions
//
export const actions = {}

//
// Reducer
//
export const reducer = createReducer({}, initialState)

//
// Selectors
//

// const root = (state) => state[key]

export const selectors = {}
