import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import Logo from './icons/logo.svg'
import Eye from './icons/eye.svg'
import Check from './icons/check.svg'


const IconWrapper = (Component, width, height) => {
  const IconWrapperStyles = {
    container: {
      width: `${width / 10}rem`,
      height: `${height / 10}rem`,
    },
  }

  const useStyles = createUseStyles(IconWrapperStyles)

  const IconAsset = (props) => {
    const classes = useStyles(props)
    const { className } = props

    return <Component className={cx(classes.asset, className)} />
  }

  IconAsset.useStyles = useStyles
  IconAsset.width = `${width / 10}rem`
  IconAsset.height = `${height / 10}rem`

  IconAsset.propTypes = {
    className: PropTypes.string,
  }
  IconAsset.defaultProps = {
    className: '',
  }

  return IconAsset
}

const icons = {
  Logo: IconWrapper(Logo, 140, 40),
  Eye: IconWrapper(Eye, 25, 16),
  Check: IconWrapper(Check, 9, 6),
}

export default icons
