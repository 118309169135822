import cloudinary from 'cloudinary-core'

import { Router } from '../routes'


export function assetUrl(asset, options = {}) {
  const cl = new cloudinary.Cloudinary({
    cloud_name: process.env.CLOUDINARY_CLOUD_NAME,
    secure: true,
  })

  if ((asset && asset.charAt(0) === '/') || asset.indexOf('http') >= 0) {
    return asset
  }

  return cl.url(asset.split('.')[0], {
    ...{
      fetchFormat: 'auto',
      quality: 'auto',
      width: 'auto',
      crop: 'scale',
      gravity: 'center',
    },
    ...options,
  })
}

export function getGoogleMapApiUrl(lat, long) {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${long}`
}

export function getYoutubeThumbnailUrl(videoUrl) {
  let videoId = false

  if (/^[a-zA-Z0-9]+$/.test(videoUrl)) {
    videoId = videoUrl
  } else {
    let matches = videoUrl.match(/https:\/\/youtu\.be\/([a-zA-Z0-9]+)/)

    if (!matches) {
      matches = videoUrl.match(/https:\/\/www.youtube.com\/watch\?v=([a-zA-Z0-9]+)/)
    }
    if (matches) {
      videoId = matches[1]
    }
  }

  return videoId ? `//img.youtube.com/vi/${videoId}/sddefault.jpg` : null
}

export function redirect(res, route, statusCode = 301) {
  if (res) {
    if (res.headersSent) {
      return
    }
    res.writeHead(statusCode, {Location: route});
    res.end();
  } else {
    Router.replaceRoute(route)
  }
}

export function routeParamSlug(fullSlug) {
  if (!fullSlug || Array.isArray(fullSlug)) {
    return fullSlug
  }

  const slug = fullSlug.replace(/^\//, '')

  return slug.indexOf('/') !== -1 ? slug.split('/') : slug
}
