/* eslint-disable max-lines */
import gql from 'graphql-tag'

// Fragments

export const UserFragment = gql`
  fragment UserFragment on Candidate {
    id
    code {
      code
      internship
      school
      started_at
      expired_at
    }
    accepts_disclaimer
    email
    name
    address
    city
    age
    phone
    schools
    internship
    work_experience
    education_other
    link
    website_other
    background
    personal_statement
    files {
      id
      description
      filename
    }
    invalid_sections
    is_complete
    started_at
    sent_at
    created_at
    updated_at
  }
`
