/* eslint-disable max-lines */
import { all, call, put, takeLatest } from 'redux-saga/effects'

import routes, { Router } from '../../routes'
import i18n from '../../i18n'
import { app } from '../../configuration'
import { actions as appActions } from '../../redux/app/redux'
import ApiSagas from '../../redux/api/sagas'

import * as services from './services'
import { actions } from './redux'


export default class ForgotPasswordSagas {

  static* init() {
    return yield call(ForgotPasswordSagas.setupSeo)
  }

  static* setupSeo() {
    yield put(
      appActions.setSeo({
        title: i18n.t('SEO_forgotPassword_title'),
        description: i18n.t('SEO_forgotPassword_description'),
        keywords: i18n.t('SEO_forgotPassword_keywords'),
        url: `${app.APP_URL}${Router.getRouteUrl(routes.forgotPassword)}`,
        noindex: false,
      })
    )
  }

  static* handleForgotPassword({ payload }) {
    const rs = yield call(ApiSagas.query, services.forgotPassword, {
      email: payload.email,
      code: payload.code,
    })

    if (rs.errors) {
      yield put(actions.forgotPasswordError(rs.errors))
      return
    }

    yield put(actions.forgotPasswordSuccess(rs.data))
    yield put(actions.setMessage(rs.data.forgotPassword.message))
  }

  static* loop() {
    yield all([
      takeLatest(actions.forgotPassword.getType(), ForgotPasswordSagas.handleForgotPassword),
    ])
  }

}
