/* eslint-disable max-lines */
import { all, call, put, takeLatest } from 'redux-saga/effects'

import routes, { Router } from '../../routes'
import i18n from '../../i18n'
import { app } from '../../configuration'
import ApiSagas from '../../redux/api/sagas'
import AuthSagas from '../../redux/auth/sagas'
import { actions as appActions } from '../../redux/app/redux'
import { actions as authActions } from '../../redux/auth/redux'

import * as services from './services'
import { actions } from './redux'


export default class ApplicationSubmitSagas {

  static* init() {
    return yield call(ApplicationSubmitSagas.setupSeo)
  }

  static* setupSeo() {
    yield put(
      appActions.setSeo({
        title: i18n.t('SEO_applicationSubmit_title'),
        description: i18n.t('SEO_applicationSubmit_description'),
        keywords: i18n.t('SEO_applicationSubmit_keywords'),
        url: `${app.APP_URL}${Router.getRouteUrl(routes.applicationPersonal)}`,
        noindex: false,
      })
    )
  }

  static* handleSubmit() {
    const rs = yield call(ApiSagas.query, services.submit, {})

    if (rs.errors) {
      yield put(actions.loginError(rs.errors))
      return
    }

    yield put(authActions.setUser(rs.data.apply))
    yield call(AuthSagas.redirectLogged)
  }

  static* loop() {
    yield all([
      takeLatest(actions.submit.getType(), ApplicationSubmitSagas.handleSubmit),
    ])
  }

}
