import { textStyles, tablet, colors } from '../../theme'


export default {
  container: {
    width: '100%',
    paddingTop: '2rem',
    paddingBottom: '4rem',
    borderTop: `1px solid ${colors.pureBlack}`,
    '@media print': {
      display: 'none',
    },
  },
  wrapper: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  contentLeft: {
    ...textStyles.tiny,
    display: 'flex',
    '& li': {
      position: 'relative',
    },
    '& > li + li': {
      marginLeft: '2.5rem',
    },
    '& > li + li::before': {
      content: '"|"',
      position: 'absolute',
      display: 'block',
      left: '-1.25rem',
      top: '50%',
      transform: 'translateY(-52%)',
    },
    ...tablet({
      ...textStyles.text,
    }),
  },
}
