/* eslint-disable camelcase */
import moment from 'moment'


const Token = (token) => token && {
  type: token?.token_type || token?.type,
  expires:
      token?.expires
      || moment()
        .add(token?.expires_in, 's')
        .toDate(),
  accessToken: token?.access_token || token?.accessToken,
  refreshToken: token?.refresh_token || token?.refreshToken,
}

Token.types = {
  Bearer: 'Bearer',
}

export default Token
