import apisauce from 'apisauce'
import { all, call, put, select, take } from 'redux-saga/effects'
import moment from 'moment'

import * as configuration from '../../configuration'
import client, { defaultConfig } from '../../graphql/Client'
import { selectors as AppSelectors } from '../app/redux'
import { GraphqlErrors } from '../../helpers/GraphqlHelpers'
import { actions as AuthActions, selectors as AuthSelectors } from '../auth/redux'

import * as queries from './queries'
import { actions, selectors } from './redux'


const DEBUG = configuration.api.DEBUG
const log = DEBUG ? console.log : () => null

export default class ApiSagas {

  static http = apisauce.create({
    baseURL: `${configuration.api.GRAPH_API_URL}`,
  })

  static init() { }

  static* initHeaders(lang) {
    const preview = yield select(AppSelectors.preview)

    yield put(
      actions.setHeaders({
        preview: preview ? 1 : 0,
        lang: lang || 'en',
      })
    )
  }

  static* getHeaders(checkToken = true) {
    const headers = yield select(selectors.headers)
    const printToken = yield select(AuthSelectors.printToken)

    let token = yield select(AuthSelectors.token)

    if (checkToken) {
      token = yield call(ApiSagas.getToken)
    }

    return {
      ...headers,
      ...(token?.accessToken && {
        Authorization: `Bearer ${token.accessToken}`,
      }),
      ...(printToken && {
        'Print-Authorization': `Bearer ${printToken}`,
      }),
    }
  }

  static query(config, variables) {
    return ApiSagas.call('query', config, variables)
  }

  static mutate(config, variables) {
    return ApiSagas.call('mutate', config, variables)
  }

  static* call(method, config, variables) {
    const headers = yield call(ApiSagas.getHeaders)

    log('ApiSagas.call', headers, { variables })
    const result = yield call(client[method], {
      ...config,
      variables,
      context: {
        headers,
      },
    })

    if (result.errors) {
      console.error(`ApiSagas: ${method}`, result.errors)
    }

    return yield call(ApiSagas.transform, result, config?.transformer)
  }

  static* transform(result, transformer) {
    if (!result.data || !transformer) {
      return result
    }

    const data = yield call(transformer, result.data)

    return { ...result, data }
  }

  static* checkTokenExpire() {
    const token = yield select(AuthSelectors.token)

    if (token && token.expires && token.refreshToken) {
      log('Api: Token expires at ', moment(token.expires).format('YYYY-MM-DD HH:mm:ss'))
      const expires = token?.expires && moment(token.expires)?.isValid() && moment(token.expires)
      const diff = -moment().diff(expires, 'minutes')

      log('Api: Token validity', diff)

      if (diff < 60) {
        log('Api: token needs refreshing')
        const result = yield call(client.query, {
          ...defaultConfig,
          query: queries.refreshToken,
          variables: {
            token: token.refreshToken,
          },
        })

        if (result.errors) {
          log('Api: refresh token error', GraphqlErrors(result.errors))
          yield put(AuthActions.setToken(null))
        } else {
          log('Api: refresh token success')
          yield put(AuthActions.setToken(result.data.refreshToken))
        }
        yield take(AuthActions.setUser.getType())
        const newToken = yield select(AuthSelectors.token)

        return newToken
      }
    }
    return token
  }

  static* getToken() {
    yield call(ApiSagas.checkTokenExpire)
    const token = yield select(AuthSelectors.token)

    return token
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
