/* eslint-disable max-lines */
import { all, call, put } from 'redux-saga/effects'

import ApiSagas from '../../redux/api/sagas'
import { actions as authActions } from '../../redux/auth/redux'

import * as services from './services'
import { actions } from './redux'

export default class PrintCandidacySagas {
  static *init({ query }) {
    yield put(authActions.setPrintToken(query.token))
    return yield call(PrintCandidacySagas.loadPage, query)
  }

  static *loadPage({ id }) {
    const result = yield call(
      ApiSagas.query,
      {
        ...services.candidateById,
      },
      {
        id,
      }
    )

    if (result.data) {
      yield all([put(actions.setData(result.data.candidateById))])
    }

    yield all([put(actions.setLoaded(true))])

    if (!result.data) {
      return { error: 404 }
    }
  }

  static *loop() {
    yield all([])
  }
}
