import { gql } from 'apollo-boost'


export const loadLocales = gql`
  query loadLocales {
    locales {
      id
      iso
      label
      isAvailableInApi
    }
  }
`
export const loadLabels = gql`
  query Labels($localeFilters: LocaleFilters!) {
    labels(localeFilters: $localeFilters) {
      key
      value
      type
    }
  }
`
