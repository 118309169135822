import { createAction, createReducer } from 'redux-act'

import {
  getServiceProps,
  reduceService,
  reduceServiceError,
  reduceServiceReset,
  reduceServiceSuccess
} from '../../helpers/ReduxHelpers'


export const key = 'Login'

//
// Initial state
//
const initialState = {
  login: getServiceProps(),
}

//
// Actions
//
export const actions = {
  login: createAction(`${key}: login`, (params) => params),
  loginSuccess: createAction(`${key}: loginSuccess`, (data) => data),
  loginError: createAction(`${key}: loginError`, (errors) => errors),
  loginReset: createAction(`${key}: loginReset`),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.login]: (state, params) => reduceService('login', state, params),
    [actions.loginSuccess]: (state, data) => reduceServiceSuccess('login', state, data),
    [actions.loginError]: (state, error) => reduceServiceError('login', state, error),
    [actions.loginReset]: (state) => reduceServiceReset('login', state),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const login = (state) => root(state).login

export const selectors = {
  login,
}
