/* eslint-disable max-lines */
import { all, call, take, put, takeLatest } from 'redux-saga/effects'

import ApiSagas from '../../redux/api/sagas'
import AuthSagas from '../../redux/auth/sagas'
import routes, { Router } from '../../routes'
import i18n from '../../i18n'
import { app } from '../../configuration'
import { actions as appActions } from '../../redux/app/redux'
import { actions as authActions } from '../../redux/auth/redux'

import * as services from './services'
import { actions } from './redux'

export default class ScholarshipSagas {
  static *init() {
    return yield call(ScholarshipSagas.setupSeo)
  }

  static *setupSeo() {
    yield put(
      appActions.setSeo({
        title: i18n.t('SEO_scholarship_title'),
        description: i18n.t('SEO_scholarship_description'),
        keywords: i18n.t('SEO_scholarship_keywords'),
        url: `${app.APP_URL}${Router.getRouteUrl(routes.scholarship)}`,
        noindex: false,
      })
    )
  }

  static *handleRegister({ payload }) {
    const rs = yield call(ApiSagas.query, services.register, {
      email: payload.email,
      password: payload.password,
      code: payload.code,
    })

    if (rs.errors) {
      yield put(actions.registerError(rs.errors))
      return
    }
    yield put(authActions.setToken(rs.data.token))
    yield take(authActions.setUser.getType())
    yield call(AuthSagas.redirectLogged)
  }

  static *loop() {
    yield all([
      //
      takeLatest(actions.register.getType(), ScholarshipSagas.handleRegister),
    ])
  }
}
