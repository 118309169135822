import merge from 'lodash/merge'

import fonts, { fontsFaces } from './fonts'
import reset from './reset'
import colors from './colors'


export default {
  '@global': merge(
    {
      ...reset,
      ...fontsFaces,
    },
    {
      html: {
        height: '100%',
      },
      body: {
        height: '100%',
        ...fonts.UniversRoman,
        color: colors.pureBlack,
        WebkitFontSmoothing: 'subpixel-antialiased',
        MozOsxFontSmoothing: 'auto',
      },
      a: {
        color: 'inherit',
      },
      'label[for]': {
        cursor: 'pointer',
      },
    }
  ),
}
