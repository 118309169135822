import { gql } from 'apollo-boost'

import { UserFragment } from '../../graphql/fragments/UserFragment'


export const candidateById = gql`
  query candidateById($id: ID!) {
    candidateById(
      id: $id
    ) {
      ...UserFragment
    }
  }
  ${UserFragment}
`
