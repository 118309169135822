const PORT = process.env.PORT !== '80' && process.env.PORT

export default {
  // APP_ENV: process.env.APP_ENV || 'production',
  APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  APP_VERSION: process.env.APP_VERSION || '',
  // BANNER: process.env.BANNER || false,
  BANNER: process.env.NEXT_PUBLIC_BANNER,
  PORT,
  APP_URL: process.env.NEXT_PUBLIC_APP_URL,
  APP_PREVIEW_URL: process.env.NEXT_PUBLIC_APP_PREVIEW_URL,
  SECURE_COOKIE: process.env.SECURE_COOKIE === 'true',
  // COOKIE_NAME: process.env.NEXT_PUBLIC_COOKIE_NAME || 'auth_token',
  COOKIE_NAME: process.env.NEXT_PUBLIC_COOKIE_NAME,
  IS_PREVIEW: process.env.IS_PREVIEW === 'true',
  NO_INDEX:
    process.env.NO_INDEX === 'true' ||
    String(process.env.NO_INDEX) === '1' ||
    false,
  USE_PWA: String(process.env.USE_PWA) === '1',
}
