const entities = [
  // Externals
  {
    key: 'loadingBar',
    reducer: require('react-redux-loading-bar').loadingBarReducer,
  },

  // Pages
  require('../pagesContents/Page/redux'),
  require('../pagesContents/Login/redux'),
  require('../pagesContents/About/redux'),
  require('../pagesContents/Scholarship/redux'),
  require('../pagesContents/SubmitSchool/redux'),
  require('../pagesContents/Disclaimer/redux'),
  require('../pagesContents/Start/redux'),
  require('../pagesContents/ApplicationCv/redux'),
  require('../pagesContents/ApplicationBackground/redux'),
  require('../pagesContents/ApplicationPersonal/redux'),
  require('../pagesContents/ApplicationSubmit/redux'),
  require('../pagesContents/Thankyou/redux'),
  require('../pagesContents/ForgotPassword/redux'),
  require('../pagesContents/ResetPassword/redux'),
  require('../pagesContents/PrintCandidacy/redux'),

  // Global
  require('./api/redux'),
  require('./app/redux'),
  require('./i18n/redux'),
  require('./auth/redux'),
  require('./tracking/redux'),
]

// Make sure all entities have a defined key
const reducers = entities.reduce((acc, entity) => {
  if (!entity.key) {
    console.error('Missing entity key')
  }
  acc[entity.key] = entity.reducer
  return acc
}, {})

export default reducers
