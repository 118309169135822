import { /* createAction, */ createReducer } from 'redux-act'

// import { GraphqlErrors } from '../../helpers/GraphqlHelpers'
// import {
//   getServiceProps,
//   reduceService,
//   reduceServiceError,
//   reduceServiceReset,
//   reduceServiceSuccess
// } from '../../helpers/ReduxHelpers'


export const key = 'ApplicationCv'

//
// Initial state
//
const initialState = {}

//
// Actions
//
export const actions = {}

//
// Reducer
//
export const reducer = createReducer(
  {},
  initialState
)

//
// Selectors
//
// const root = (state) => state[key]

export const selectors = {}
