import { createAction, createReducer } from 'redux-act'

import { GraphqlErrors } from '../../helpers/GraphqlHelpers'
import {
  getServiceProps,
  reduceService,
  reduceServiceError,
  reduceServiceReset,
  reduceServiceSuccess
} from '../../helpers/ReduxHelpers'


export const key = 'Start'

//
// Initial state
//
const initialState = {
  start: getServiceProps(),
}

//
// Actions
//
export const actions = {
  start: createAction(`${key}: start`, (params) => params),
  startSuccess: createAction(`${key}: startSuccess`, (data) => data),
  startError: createAction(`${key}: startError`, (errors) => errors),
  startReset: createAction(`${key}: startReset`),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.start]: (state, params) => reduceService('start', state, params),
    [actions.startSuccess]: (state, data) => reduceServiceSuccess('start', state, data),
    [actions.startError]: (state, error) => reduceServiceError('start', state, GraphqlErrors(error)),
    [actions.startReset]: (state) => reduceServiceReset('start', state),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const start = (state) => root(state).start

export const selectors = {
  start,
}
