import { actions } from './redux'


const isGAIDExist = typeof process.env.GA_ID === 'string' && process.env.GA_ID.length > 0

const trackPage = (store, url) => {
  const enabled = store.getState().tracking.enabled

  if (!enabled || !isGAIDExist) {
    return
  }

  window.gtag('config', process.env.GA_ID, {
    page_path: url,
  })

  if (process.env.GW_ID) {
    window.gtag('config', process.env.GW_ID, {
      page_path: url,
    })
  }
}

const trackEvent = (store, payload) => {
  const enabled = store.getState().tracking.enabled

  if (!enabled || !isGAIDExist) {
    return
  }
  const { category: event_category, action, label: event_label, value } = payload

  // Send event
  window.gtag('event', action, {
    event_category,
    event_label,
    value,
  })
}

const TrackingMiddleware = (store) => (next) => (action) => {
  const actionMap = {
    [actions.trackPage]: trackPage,
    [actions.trackEvent]: trackEvent,
  }

  if (actionMap[action.type] !== undefined) {
    actionMap[action.type](store, action.payload)
  }

  next(action)
}

export default TrackingMiddleware
