import { gql } from 'apollo-boost'


export const resetPassword = gql`
  mutation resetPassword(
    $code: String!
    $token: String!
    $email: String!
    $password: String!
    $password_confirmation: String!
) {
  resetPassword(
    token: $token
    code: $code
    email: $email
    password: $password
    password_confirmation: $password_confirmation
  ) {
    message
  }
}
`
