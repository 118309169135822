import { gql } from 'apollo-boost'

import { UserFragment } from '../../graphql/fragments/UserFragment'


export const apply = gql`
  mutation apply {
    apply {
       ...UserFragment
    }
  }
  ${UserFragment}
`

