/* eslint-disable complexity */
import React, { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import A from '../A'
import Icon from '../Icon'
import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StyleUtils'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ActionButton = (props) => {
  const {
    className,
    label,
    icon,
    isDisabled,
    isSuccess,
    link,
    route,
    routeParams,
    target,
    onClick,
    iconRight,
    title,
    variant,
    isRequired,
    type,
    classes: classesProp,
    ...otherProps
  } = props

  const [hasHover, setHasHover] = useState(false)

  useEffect(() => {
    setHasHover(
      !(
        'ontouchstart' in document.documentElement
        || navigator.maxTouchPoints > 0
        || navigator.msMaxTouchPoints > 0
      )
    )
  }, [])

  const classesComp = useStyles({ ...props, hasHover })
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  // to compensate for JSS failure
  const isDisabledClass = isDisabled === true ? classes.containerDisabled : null

  const isVariantClass = variant === 'transparent'
    ? classes.containerTransparent : classes.containerDefault

  const iconRightClass = iconRight ? classes.iconRight : null

  const renderIcon = icon && (
    <Icon
      icon={icon}
      className={classes.icon}
      color="currentColor"
      iconClassName={classes.iconAsset}
    />
  )

  const content = (
    <>
      {icon && renderIcon}
      {label}
    </>
  )

  const renderSubmit = (
    <button
      type="submit"
      {...otherProps}
      className={cx(
        classes.container,
        className,
        iconRightClass,
        isVariantClass,
        isDisabledClass
      )}
      onClick={onClick}
    >
      {content}
    </button>
  )

  const renderDefault = (
    <div
      {...otherProps}
      className={cx(
        classes.container,
        className,
        iconRightClass,
        isVariantClass,
        isDisabledClass
      )}
      onClick={!isDisabled ? onClick : null}
      role="presentation"
    >
      {content}
      {isRequired && <span className={classes.spark}>*</span>}
    </div>
  )

  const renderAsLink = (
    <A
      {...otherProps}
      href={link}
      route={route}
      routeParams={routeParams}
      target={target}
      title={title || label}
      className={cx(
        classes.container,
        className,
        iconRightClass,
        isVariantClass,
        isDisabledClass
      )}
    >
      {content}
      {isRequired && <span className={classes.spark}>*</span>}
    </A>
  )

  return type === 'submit'
    ? renderSubmit
    : !isDisabled && !isSuccess && (link || route)
      ? renderAsLink
      : renderDefault
}

ActionButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  label: PropTypes.string,
  icon: PropTypes.func,
  variant: PropTypes.oneOf([
    'default',
    'transparent',
  ]),
  isDisabled: PropTypes.bool,
  isSuccess: PropTypes.bool,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  target: PropTypes.string,
  onClick: PropTypes.func,
  iconRight: PropTypes.bool,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button']),
}

ActionButton.defaultProps = {
  className: '',
  classes: {},
  label: '',
  icon: null,
  variant: 'default',
  isDisabled: false,
  isSuccess: false,
  link: null,
  route: null,
  routeParams: null,
  target: null,
  onClick: () => null,
  iconRight: false,
  title: null,
  isRequired: false,
  type: null,
}

export default withMemo()(ActionButton)
