const API_URL = process.env.NEXT_PUBLIC_API_URL
const API_URL_CLIENT = process.env.NEXT_PUBLIC_API_URL_CLIENT || API_URL
const GRAPH_API_URL = process.env.NEXT_PUBLIC_GRAPH_API_URL || `${API_URL}/graphql`
const GRAPH_API_URL_CLIENT = process.env.NEXT_PUBLIC_GRAPH_API_URL_CLIENT || `${API_URL_CLIENT}/graphql`
const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL
const UPLOAD_ENDPOINT = `${API_URL}/api/image/upload`
const UPLOAD_ENDPOINT_CLIENT = `${API_URL_CLIENT}/api/image/upload`

export default {
  DEBUG: true,
  API_URL: process.browser ? API_URL_CLIENT : API_URL,
  GRAPH_API_URL: process.browser ? GRAPH_API_URL_CLIENT : GRAPH_API_URL,
  UPLOAD_ENDPOINT: process.browser ? UPLOAD_ENDPOINT_CLIENT : UPLOAD_ENDPOINT,
  BACKEND_URL,
}
