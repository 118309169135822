import { createAction } from 'redux-act'

/**
 *
 * @param {string} prefix - the prefix applied to each actions
 * @param {object.<function?>} reducers
 * @returns {object.<BaseActionCreator>}
 */
export function createActions(prefix, reducers) {
  return Object.keys(reducers).reduce((acc, key) => {
    const current = reducers[key]
    const name = `${prefix}: ${key}`
    const reducer = typeof current === 'function' ? current : (arg) => arg

    return { ...acc, [key]: createAction(name, reducer) }
  }, {})
}
