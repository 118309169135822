import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import AppConfig from '../../configuration/app'


export const key = 'app'

export const pageType = {
  // HOME: 'HOME',
}

export const flexibleType = {
  // [pageType.HOME]: 'HOMEPAGE_BLOCK',
}

export const resourceType = {
  PAGE: 'page',
  POST: 'post',
}

//
// Initial state
//
const initialState = {
  route: null,
  preview: AppConfig.IS_PREVIEW,
  resource: { type: null, id: null },
  headers: null,
  footers: null,
  menuOpen: false,
  pages: {},
  breadcrumb: null,
  cookiesSettings: false,
  seo: null,
  jsonld: null,
  alternates: null,
}

//
// Actions
//
export const actions = {
  setRoute: createAction('App: setRoute', (route) => route),
  setPreview: createAction('App: setPreview', (preview) => preview),
  setResource: createAction('App: setResource', (type, id) => ({ type, id })),
  setHeaders: createAction('App: setHeaders', (headers) => headers),
  setFooters: createAction('App: setFooters', (footers) => footers),
  setMenuOpen: createAction('App: setMenuOpen', (menuOpen) => menuOpen),
  setPages: createAction('App: setPages', (pages) => pages),
  setAlternates: createAction('App: setAlternates', (alternates) => alternates),
  setBreadcrumb: createAction('App: setBreadcrumb', (breadcrumb) => breadcrumb),
  scrollTop: createAction('App: scrollTop'),
  openCookiesSettings: createAction('App: Open cookies settings'),
  closeCookiesSettings: createAction('App: Close cookies settings'),
  setSeo: createAction('App: setSeo', (seo) => seo),
  setJsonld: createAction('App: setJsonld', (jsonld) => jsonld),
  initBrowser: createAction('App: initBrowser'),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setRoute]: (state, route) => Immutable.merge(state, { route }),
    [actions.setPreview]: (state, preview) => Immutable.merge(state, { preview }),
    [actions.setResource]: (state, resource) => Immutable.merge(state, { resource }),
    [actions.setHeaders]: (state, headers) => Immutable.merge(state, { headers }),
    [actions.setFooters]: (state, footers) => Immutable.merge(state, { footers }),
    [actions.setAlternates]: (state, alternates) => Immutable.merge(state, { alternates }),
    [actions.setMenuOpen]: (state, menuOpen) => Immutable.merge(state, { menuOpen }),
    [actions.setPages]: (state, pages) => Immutable.merge(state, { pages }),
    [actions.setBreadcrumb]: (state, breadcrumb) => Immutable.merge(state, { breadcrumb }),
    [actions.scrollTop]: (state, url) => {
      window.scrollTo(0, 0)
      return state
    },
    [actions.openCookiesSettings]: (state) => Immutable.merge(state, { cookiesSettings: true }),
    [actions.closeCookiesSettings]: (state) => Immutable.merge(state, { cookiesSettings: false }),
    [actions.setSeo]: (state, seo) => Immutable.merge(state, { seo }),
    [actions.setJsonld]: (state, jsonld) => Immutable.merge(state, { jsonld }),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const route = (state) => root(state).route
const preview = (state) => root(state).preview
const resource = (state) => root(state).resource
const headers = (state) => root(state).headers
const footers = (state) => root(state).footers
const alternates = (state) => root(state).alternates
const menuOpen = (state) => root(state).menuOpen
const pages = (state) => root(state).pages
const page = (id) => (state) => pages(state)?.[id] || {}
const breadcrumb = (state) => root(state).breadcrumb
const sectors = (state) => root(state).sectors
const cookiesSettings = (state) => root(state).cookiesSettings
const seo = (state) => root(state).seo
const jsonld = (state) => root(state).jsonld

export const selectors = {
  route,
  preview,
  resource,
  menuOpen,
  alternates,
  pages,
  page,
  breadcrumb,
  sectors,
  cookiesSettings,
  seo,
  jsonld,
  headers,
  footers,
}
