/* eslint-disable max-lines */
import { all, call, put, takeLatest } from 'redux-saga/effects'

import ApiSagas from '../../redux/api/sagas'
import AuthSagas from '../../redux/auth/sagas'
import routes, { Router } from '../../routes'
import i18n from '../../i18n'
import { app } from '../../configuration'
import { actions as appActions } from '../../redux/app/redux'
import { actions as AuthActions } from '../../redux/auth/redux'

import * as services from './services'
import { actions } from './redux'


export default class StartSagas {

  static* init() {
    return yield call(StartSagas.setupSeo)
  }

  static* setupSeo() {
    yield put(
      appActions.setSeo({
        title: i18n.t('SEO_start_title'),
        description: i18n.t('SEO_start_description'),
        keywords: i18n.t('SEO_start_keywords'),
        url: `${app.APP_URL}${Router.getRouteUrl(routes.start)}`,
        noindex: false,
      })
    )
  }

  static* handleStart() {
    const rs = yield call(ApiSagas.query, services.startApplication, {})

    if (rs.errors) {
      yield put(actions.startApplicationError(rs.errors))
      return
    }

    yield put(AuthActions.setUser(rs.data.startApplication))
    yield call(AuthSagas.redirectLogged)
  }

  static* loop() {
    yield all([
      //
      takeLatest(actions.start.getType(), StartSagas.handleStart),
    ])
  }

}
