import React from 'react'
import PropTypes from 'prop-types'

import withMemo from '../../decorators/withMemo'
import LayoutDefault from '../../components/LayoutDefault'
import Header from '../../components/Header'
import Feedback from '../../components/Feedback'
import Footer from '../../components/Footer'


const ErrorTemplate = ({ layoutProps, headerProps, feedbackProps, footerProps }) => (
  <LayoutDefault
    {...layoutProps}
    header={(
      <Header {...headerProps} />
    )}
    content={(
      <>
        <Feedback {...feedbackProps} />
      </>
    )}
    footer={(
      <Footer {...footerProps} />
    )}
  />
)

ErrorTemplate.propTypes = {
  layoutProps: PropTypes.any,
  headerProps: PropTypes.any,
  feedbackProps: PropTypes.any,
  footerProps: PropTypes.any,
}

ErrorTemplate.defaultProps = {
  layoutProps: null,
  headerProps: null,
  feedbackProps: null,
  footerProps: null,
}

export default withMemo()(ErrorTemplate)

