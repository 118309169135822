import { colors, fonts } from '../../theme'


export default {
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.1rem 2rem 0.7rem',
    minHeight: '3.6rem',
    minWidth: '14rem',
    paddingLeft: (props) => props.icon && !props.iconRight && '2rem',
    paddingRight: (props) => props.icon && props.iconRight && '2rem',
    border: '1px solid',
    borderRadius: '3rem',
    lineHeight: '1.2',
    fontSize: '1.2rem',
    ...fonts.UniversRoman,
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: '0.05em',
    userSelect: 'none',
    cursor: 'pointer',
    transition: 'color .3s, border-color .3s, background-color .3s',
  },
  // specific states
  containerDefault: {
    '&:hover': {
      backgroundColor: colors.lightGrey,
    },
  },
  containerTransparent: {
    borderColor: 'transparent',
    '&:hover': {
      borderColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  containerDisabled: {
    color: colors.midGrey,
    borderColor: colors.midGrey,
    backgroundColor: colors.pureWhite,
    cursor: 'not-allowed',
    '&:hover': {
      color: colors.midGrey,
      borderColor: colors.midGrey,
      backgroundColor: colors.pureWhite,
    },
  },
  // icons
  icon: {
    position: 'relative',
    marginRight: (props) => props.label && !props.iconRight && '1.2rem',
    marginLeft: (props) => props.label && props.iconRight && '1.2rem',
  },
  iconAsset: {},
  iconRight: {
    flexDirection: 'row-reverse !important',
    justifyContent: 'space-between',
  },
}
