import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import { GraphqlErrors } from '../../helpers/GraphqlHelpers'
import {
  getServiceProps,
  reduceService,
  reduceServiceError,
  reduceServiceReset,
  reduceServiceSuccess
} from '../../helpers/ReduxHelpers'


export const key = 'ResetPassword'

//
// Initial state
//
const initialState = {
  resetPassword: getServiceProps(),
  message: null,
}

//
// Actions
//
export const actions = {
  resetPassword: createAction(`${key}: resetPassword`, (params) => params),
  resetPasswordSuccess: createAction(`${key}: resetPasswordSuccess`, (data) => data),
  resetPasswordError: createAction(`${key}: resetPasswordError`, (errors) => errors),
  resetPasswordReset: createAction(`${key}: resetPasswordReset`),
  setMessage: createAction('ResetPassword: setMessage', (message) => ({ message })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.resetPassword]: (state, params) => reduceService('resetPassword', state, params),
  [actions.resetPasswordSuccess]: (state, data) => reduceServiceSuccess('resetPassword', state, data),
  [actions.resetPasswordError]: (state, error) => reduceServiceError('resetPassword', state, GraphqlErrors(error)),
  [actions.resetPasswordReset]: (state) => reduceServiceReset('resetPassword', state),
  [actions.setMessage]: (state, { message }) => Immutable.static.merge(state, { message }),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const resetPassword = (state) => root(state).resetPassword
const message = (state) => root(state).message

export const selectors = {
  resetPassword,
  message,
}
