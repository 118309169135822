/* eslint-disable camelcase */
import { assetUrl } from './UrlHelpers'


export const pageJsonLd = ({
  share,
  header_title,
  header_subtitle,
  created_at,
  updated_at,
  url,
  author = 'Balenciaga',
  language = 'en',
}) => ({
  '@context': 'http://schema.org',
  '@type': 'WebPage',
  name: share?.title,
  description: share?.description,
  image: share?.image ? assetUrl(share.image, { width: 1024 }) : '',
  url,
  headline: `${header_title || ''} ${header_subtitle || ''}`,
  datePublished: created_at,
  dateModified: updated_at,
  author,
  inLanguage: language,
})
