/* eslint-disable max-lines */
import { all, call, put, takeLatest } from 'redux-saga/effects'

import routes, { Router } from '../../routes'
import i18n from '../../i18n'
import { app } from '../../configuration'
import { actions as appActions } from '../../redux/app/redux'
import ApiSagas from '../../redux/api/sagas'

import * as services from './services'
import { actions } from './redux'


export default class ResetPasswordSagas {

  static* init() {
    return yield call(ResetPasswordSagas.setupSeo)
  }

  static* setupSeo() {
    yield put(
      appActions.setSeo({
        title: i18n.t('SEO_resetPassword_title'),
        description: i18n.t('SEO_resetPassword_description'),
        keywords: i18n.t('SEO_resetPassword_keywords'),
        url: `${app.APP_URL}${Router.getRouteUrl(routes.resetPassword)}`,
        noindex: false,
      })
    )
  }

  static* handleResetPassword({ payload }) {
    const rs = yield call(ApiSagas.query, services.resetPassword, {
      token: payload.token,
      code: payload.code,
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    })

    if (rs.errors) {
      yield put(actions.resetPasswordError(rs.errors))
      return
    }

    yield put(actions.resetPasswordSuccess(rs.data))
    yield put(actions.setMessage(rs.data.resetPassword.message))
  }

  static* loop() {
    yield all([
      takeLatest(actions.resetPassword.getType(), ResetPasswordSagas.handleResetPassword),
    ])
  }

}
