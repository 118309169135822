import { textStyles } from '../../theme'


export default {
  container: {
  },
  wrapper: {
    maxWidth: (props) => `${props?.theme?.maxWidth / 10}rem` || 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > * + *': {
      marginTop: '2.5rem',
    },
  },
  text: {
    ...textStyles.text,
    textAlign: (props) => props?.theme?.paragraphTextAlign || 'center',
    '& > * + *': {
      marginTop: '0.5rem',
    },
  },
  actions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: '2.5rem',
    },
  },
}
