// Important KEEP IN VANILLA JAVASCRIPT
const ApolloBoost = require('apollo-boost')
const fetch = require('cross-fetch')

import bugsnagClient from '../helpers/BugsnagHelpers'

const config = require('../configuration')


const ApolloClient = ApolloBoost.default
const endPoint = config.api.GRAPH_API_URL
const FILTERED_CATEGORIES = ['validation', 'authorization', 'authentication']

const GraphQLClient = new ApolloClient({
  uri: endPoint,
  fetch,
  onError: ({ networkError, graphQLErrors, response, operation }) => {
    if (bugsnagClient) {
      if (graphQLErrors) {
        const filteredErrors = graphQLErrors.filter(({ extensions }) => !FILTERED_CATEGORIES.includes(extensions.category))

        filteredErrors.forEach(({ message, locations, path, ...additional }) => {
          const messageToSend = `[GraphQL error]: \
            Message: ${JSON.stringify(message)}, \
            Location: ${JSON.stringify(locations)}, \
            Path: ${JSON.stringify(path)}, \
            Additional: ${JSON.stringify(additional)}`

          if (config.app.APP_ENV === 'local') {
            console.error(messageToSend)
          }

          bugsnagClient.notify(new Error(messageToSend), {
            severity: 'error',
            context: 'graphQLErrors',
            metaData: {
              Message: message,
              Location: locations,
              Path: path,
              Operation: operation,
              Response: response,
              Additional: additional,
            },
          })
        })
      }
      if (networkError) {
        if (config.app.APP_ENV === 'local') {
          console.error(`[Network error]: ${networkError}`)
        }
        bugsnagClient.notify(new Error(`[Network error]: ${networkError}`), {
          severity: 'error',
          context: 'graphQLNetworkError',
          metaData: {
            Operation: operation,
            Response: response,
          },
        })
      }
    }
  },
})

export const defaultConfig = {
  fetchPolicy: 'no-cache',
  errorPolicy: 'all',
}

export default GraphQLClient
