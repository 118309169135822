import nextRoutes from 'next-routes'

import A from '../components/A'

import r from './definitions'

const routes = r
const router = nextRoutes()

const defaultParams = { lang: 'en' }

Object.values(routes).map(({ component, path, name }) =>
  router.add({
    page: component,
    name,
    pattern: path,
  })
)

router.Router.getRouteUrl = (route, params = {}) => {
  if (!route || !route.name) {
    return ''
  }
  let routeUrl

  const p = {
    ...(route?.path?.indexOf(':lang') !== -1 ? defaultParams : {}),
    ...params,
  }

  try {
    routeUrl = router.findAndGetUrls(route.name, p)?.urls?.as
  } catch (e) {
    console.warn('findAndGetUrls Error', e)
    return ''
  }

  Object.keys(p).forEach(key => {
    routeUrl = routeUrl.replace(new RegExp(`:${key}\\??`, 'gm'), p[key])
    routeUrl = decodeURIComponent(routeUrl)
    routeUrl = routeUrl.replace('//', '/')
  })
  return routeUrl
}

export default routes
export const Link = router.Link
export const Router = router.Router
A.setLinkComponent(router.Link)
A.setLinkParamsTransformer(props => {
  const route = props.route || props.href
  const prefetch = props?.prefetch

  if (!route || route.indexOf('http') === 0) {
    return false
  }

  let params = props.routeParams || {}

  const registeredRoute = routes[route]

  if (!registeredRoute) {
    return {
      route: route.indexOf('/') === 0 ? route : `/${route}`,
      params,
      prefetch,
    }
  }

  if (registeredRoute?.path?.indexOf(':lang') !== -1) {
    params = { ...defaultParams, ...params }
  }

  const matches = registeredRoute.path.match(/:([a-z0-9?]+)/gi)

  if (matches) {
    const missingKeys = Array.from(matches)
      ?.map(m => m.substr(1))
      .filter(m => m && m.indexOf('?') === -1 && (!params || !params[m]))

    if (missingKeys.length) {
      console.warn(`[A] param "${missingKeys[0]}" is not defined`, { route })
      return false
    }
  }

  return {
    route,
    params,
    prefetch,
  }
})
