import { gql } from 'apollo-boost'

import { UserFragment } from '../../graphql/fragments/UserFragment'


export const me = gql`
  query me {
    me {
      ...UserFragment
    }
  }
  ${UserFragment}
`

export const logout = gql`
  mutation logout {
    logout {
      message
    }
  }
`

export const updateCv = gql`
  mutation updateCv(
    $name: String
    $address: String
    $city: String
    $age: Int
    $phone: String
    $schools: String
    $internship: String
    $work_experience: String
    $education_other: String
    $link: String
    $website_other: String
    $background: String
    $personal_statement: String
) {
  updateCv(
    name: $name
    address: $address
    city: $city
    age: $age
    schools: $schools
    phone: $phone
    internship: $internship
    work_experience: $work_experience
    education_other: $education_other
    link: $link
    website_other: $website_other
    background: $background
    personal_statement: $personal_statement
  ) {
    ...UserFragment
  }
}
  ${UserFragment}
`

export const updateFiles = gql`
  mutation updateFiles(
    $create: [CreateFileInput!]
    $update: [UpdateFileInput!]
    $delete: [ID!]
) {
  updateFiles(
    create: $create
    update: $update
    delete: $delete
  ) {
    ...UserFragment
  }
}
  ${UserFragment}
`
