import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'

import styles from './styles'


let Link = null

let LinkParamsTransformer = (props) => props

const useStyles = createUseStyles(styles)

const A = (props) => {
  const classes = useStyles(props)
  const {
    children,
    className,
    onClick,
    disabled,
    href,
    route,
    target,
    title,
    rel,
    enabled,
    routeParams,
    ...otherProps
  } = props

  const renderDefault = useMemo(
    () => (
      <div
        className={className}
        onClick={!disabled ? onClick : undefined}
      >
        {children}
      </div>
    ),
    [className, disabled, onClick, children]
  )

  const params = LinkParamsTransformer(props)
  const renderAsLink = (target && target !== '_self')
    || (href && (href.match(/^(h|\/\/)/) || href.match(/^mailto:/)))
    || !Link
    || !params ? (
      <a
        {...otherProps}
        title={title}
        href={href}
        aria-label={title}
        target={target}
        className={cx(classes.link, className)}
        onClick={onClick}
        rel={rel}
      >
        {children}
      </a>
    ) : (
      <Link {...params} legacyBehavior>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <a
          {...otherProps}
          title={title}
          aria-label={title}
          className={cx(classes.link, className)}
          onClick={onClick}
        >
          {children}
        </a>
      </Link>
    )

  return <>{(href || route) && !disabled ? renderAsLink : renderDefault}</>
}

A.setLinkComponent = (linkComponent) => {
  Link = linkComponent
}

A.setLinkParamsTransformer = (linkParamsTransformer) => {
  LinkParamsTransformer = linkParamsTransformer
}

A.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  href: PropTypes.string,
  route: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  routeParams: PropTypes.object,
  target: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  enabled: PropTypes.bool,
  rel: PropTypes.string,
}

A.defaultProps = {
  className: null,
  children: null,
  onClick: () => undefined,
  href: null,
  route: null,
  routeParams: null,
  target: null,
  title: null,
  disabled: false,
  enabled: false,
  rel: 'nofollow noreferrer',
}

export default withMemo()(A)
