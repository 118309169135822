import withRedux from 'next-redux-wrapper'
import React, { useEffect, useMemo } from 'react'
import { JssProvider, ThemeProvider } from 'react-jss'
import { Provider } from 'react-redux'
import LogRocket from 'logrocket'

import AppGate from '../containers/AppGate'
import I18nProvider from '../i18n/Provider'
import createStore from '../redux'
import getPageContext from '../theme/context'
import AppSagas from '../redux/app/sagas'
import bugsnagClient from '../helpers/BugsnagHelpers'

import { app } from '../configuration'


const MyApp = (props) => {
  // eslint-disable-next-line react/prop-types
  const { store, ...pageProps } = props

  if (typeof window === "undefined") {
    const originalWarn = console.warn;
    console.warn = (...args) => {
      if (
        args[0] !==
        'Warning: [JSS] Rule is not linked. Missing sheet option "link: true".'
      ) {
        originalWarn(...args);
      }
    };
  }

  const pageContext = useMemo(getPageContext, [])

 /* useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')

    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }

    if (!app.USE_PWA) {
      try {
        // eslint-disable-next-line no-unused-expressions
        navigator?.serviceWorker?.getRegistrations().then((registrations) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const registration of registrations) {
            registration.unregister()
          }
        })
      } catch (e) {
        console.log('service worker unregister error', e)
      }
    }
  }, [])*/

  useEffect(() => {
    LogRocket.init('oht1mo/balenciaga-cv')
  }, [])

  return (
    <>
      <JssProvider
        registry={pageContext.sheetsRegistry}
        generateClassName={pageContext.generateClassName}
        jss={pageContext.jss}
        generateId={pageContext.generateId}
      >
        <ThemeProvider theme={pageContext.theme}>
          <Provider store={store}>
            <I18nProvider store={store}>
              <AppGate
                pageContext={pageContext}
                {...pageProps}
              />
            </I18nProvider>
          </Provider>
        </ThemeProvider>
      </JssProvider>
    </>
  )
}

MyApp.getInitialProps = async({ Component, ctx }) => {
  try {
    const props = await ctx.store.saga
      .run(AppSagas.init, ctx, Component?.getInitialProps)
      .toPromise()

    return props
  } catch (e) {
    if (bugsnagClient) {
      bugsnagClient.notify(e)
    }
    console.error(e)
  }
}

export default withRedux(createStore)(MyApp)
