import { defaultConfig } from '../../graphql/Client'

import * as queries from './queries'
import * as transformers from './transformers'


export const pageBySlug = {
  ...defaultConfig,
  query: queries.pageBySlug,
  transformer: transformers.pageBySlug,
}
