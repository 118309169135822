/* eslint-disable max-lines */
import { all, call, put, take, takeLatest } from 'redux-saga/effects'
import LogRocket from 'logrocket'

import { actions as authActions } from '../../redux/auth/redux'
import ApiSagas from '../../redux/api/sagas'
import AuthSagas from '../../redux/auth/sagas'
import routes, { Router } from '../../routes'
import i18n from '../../i18n'
import { app } from '../../configuration'
import { actions as appActions } from '../../redux/app/redux'

import * as services from './services'
import { actions } from './redux'

export default class LoginSagas {
  static *init() {
    return yield call(LoginSagas.setupSeo)
  }

  static *setupSeo() {
    yield put(
      appActions.setSeo({
        title: i18n.t('SEO_login_title'),
        description: i18n.t('SEO_login_description'),
        keywords: i18n.t('SEO_login_keywords'),
        url: `${app.APP_URL}${Router.getRouteUrl(routes.login)}`,
        noindex: false,
      })
    )
  }

  static *handleLogin({ payload }) {
    const rs = yield call(ApiSagas.query, services.login, {
      email: payload.email,
      password: payload.password,
      code: payload.code,
    })

    if (rs.errors) {
      yield put(actions.loginError(rs.errors))
      return
    }

    LogRocket.identify(rs?.data?.user?.id ?? 'no-identity-given', {
      name: rs?.data?.user?.id ?? 'no-name-given',
      email: rs?.data?.user?.email ?? '',
    })

    yield put(authActions.setToken(rs.data.token))
    yield take(authActions.setUser.getType())
    yield call(AuthSagas.redirectLogged)
  }

  static *loop() {
    yield all([
      //
      takeLatest(actions.login.getType(), LoginSagas.handleLogin),
    ])
  }
}
