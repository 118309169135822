export const me = (data) => data?.me

// eslint-disable-next-line complexity
export const CandidateTransformer = (payload) => ({
  ...(payload?.name || payload?.name === '') ? { name: payload?.name ?? '' } : {},
  ...(payload?.address || payload?.address === '') ? { address: payload?.address ?? '' } : {},
  ...(payload?.city || payload?.city === '') ? { city: payload?.city ?? '' } : {},
  ...(payload?.age || payload?.age === '') ? { age: payload.age ? parseInt(payload.age, 10) : undefined } : {},
  ...(payload?.phone || payload?.phone === '') ? { phone: payload?.phone ?? '' } : {},
  ...(payload?.schools || payload?.schools === '') ? { schools: payload?.schools ?? '' } : {},
  ...(payload?.internship || payload?.internship === '') ? { internship: payload?.internship ?? '' } : {},
  ...(payload?.experience || payload?.experience === '') ? { work_experience: payload?.experience ?? '' } : {},
  ...(payload?.other_education || payload?.other_education === '') ? { education_other: payload?.other_education ?? '' } : {},
  ...(payload?.link || payload?.link === '') ? { link: payload?.link ?? '' } : {},
  ...(payload?.other_link || payload?.other_link === '') ? { website_other: payload?.other_link ?? '' } : {},
  ...(payload?.background || payload?.background === '') ? { background: payload?.background ?? '' } : {},
  ...(payload?.personal_statement || payload?.personal_statement === '') ? { personal_statement: payload?.personal_statement ?? '' } : {},
})
