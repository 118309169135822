const transformError = (error) => {
  console.log(
    'get error',
    error?.extensions?.category,
    error?.extensions[error?.extensions?.category]
  )
  if (error?.extensions?.category === 'validation') {
    const validation = error?.extensions[error?.extensions?.category]

    return Object.keys(validation).reduce(
      (r, v) => ({
        field: v,
        label: validation[v][0],
      }),
      {}
    )
  }
  if (error?.extensions?.category === 'auth') {
    return {
      label: error?.extensions?.reason,
    }
  }

  if (error?.message) {
    return {
      label: error.message,
    }
  }

  return {}
}

export const GraphqlErrors = (errors) => (errors && Array.isArray(errors) && errors.map(transformError)) || []
