/* eslint-disable complexity */
import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'
import ClassNames from 'classnames'
import { createUseStyles } from 'react-jss'

import A from '../A'
import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'
import { useSafeCall } from '../../helpers/React'
import AccessibleHiddenText from '../AccessibleHiddenText'

import styles from './styles'
import icons from './icons'


const useStyles = createUseStyles(styles)

function Icon(props) {
  const {
    className,
    iconClassName,
    classes: classesProp,
    icon,
    color,
    link,
    route,
    routeParams,
    target,
    onClick,
    title,
    iconProps,
    alt,
  } = props

  const IconComponent = typeof icon === 'string' ? icons[icon] : icon
  const classesComp = useStyles(props)
  const classesIcon = IconComponent?.useStyles(props)
  const classes = useMemo(() => mergeStyles(mergeStyles(classesComp, classesIcon), classesProp), [
    classesComp,
    classesIcon,
    classesProp,
  ])
  const handleClick = useSafeCall(onClick)

  const renderAsLink = (
    <A
      className={ClassNames(classes.container, className, color)}
      onClick={handleClick}
      href={link}
      route={route}
      routeParams={routeParams}
      title={title}
      target={target}
      rel="noreferrer"
    >
      {IconComponent && (
        <IconComponent
          {...iconProps}
          className={ClassNames(classes.icon, iconClassName, iconProps.className)}
        />
      )}
      {alt && <AccessibleHiddenText text={alt} />}
    </A>
  )

  const renderDefault = (
    <span
      className={ClassNames(classes.container, className, color)}
      onClick={handleClick}
      role="presentation"
    >
      {IconComponent && (
        <IconComponent
          {...iconProps}
          className={ClassNames(classes.icon, iconClassName, iconProps.className)}
        />
      )}
      {alt && <AccessibleHiddenText text={alt} />}
    </span>
  )

  return link || route ? renderAsLink : renderDefault
}

Icon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf(Object.keys(icons))]).isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  onClick: PropTypes.func,
  link: PropTypes.string,
  route: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  routeParams: PropTypes.object,
  target: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
}

Icon.defaultProps = {
  classes: null,
  color: null,
  iconClassName: null,
  className: null,
  onClick: () => undefined,
  link: null,
  route: null,
  routeParams: null,
  target: null,
  title: null,
  alt: null,
  iconProps: {
    className: null,
  },
}

export default withMemo()(Icon)
