/* eslint-disable max-lines */
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'

import {
  getAuthCookie,
  removeAuthCookie,
  setAuthCookie,
} from '../../helpers/CookieHelpers'
import { redirect } from '../../helpers/UrlHelpers'
import routes, { Router } from '../../routes'
import ApiSagas from '../api/sagas'
import { selectors as AppSelectors } from '../app/redux'
import * as configuration from '../../configuration'
import { APPLICATIONS_SECTIONS } from '../../graphql/enums/applicationSections'

import { selectors as AuthSelectors, actions, selectors } from './redux'
import * as services from './services'
import { CandidateTransformer } from './transformers'

const DEBUG = configuration.api.DEBUG
const log = DEBUG ? console.log : () => null

export default class AuthSagas {
  static req = null

  static res = null

  static *init(ctx) {
    AuthSagas.req = ctx?.req
    AuthSagas.res = ctx?.res

    const cookie = getAuthCookie(AuthSagas.req)
    const token = yield select(selectors.token)

    // clear user
    if (!process.browser) {
      yield put(actions.setUser(null))
    }

    if (cookie) {
      if (!token || token?.accessToken !== cookie?.accessToken) {
        yield put(actions.setToken(cookie))
        yield take(actions.setUser.getType())
      }
    } else if (token) {
      yield put(actions.setToken(null))
      yield take(actions.setUser.getType())
    }

    yield call(AuthSagas.redirectLogged, ctx)
  }

  static *redirectToPage(res = AuthSagas.res, destinationRoute) {
    yield call(redirect, res, Router.getRouteUrl(destinationRoute), 303)
  }

  static *redirectHome() {
    const isConnected = yield select(selectors.isConnected)

    if (!isConnected) {
      return yield call(AuthSagas.redirectLogged)
    }
    return yield call(AuthSagas.redirectLogged)
  }

  static *redirectLogged() {
    const isConnected = yield select(selectors.isConnected)
    const { pathname } = yield select(AppSelectors.route)
    const user = yield select(AuthSelectors.user)

    if (
      !isConnected &&
      (AuthSagas?.req?.url === '/home' || AuthSagas?.req?.url === '/')
    ) {
      yield call(AuthSagas.redirectToPage, AuthSagas.res, routes.scholarship)
      return true
    }

    if (
      pathname === routes.page.component &&
      AuthSagas?.req?.url !== '/home' &&
      AuthSagas?.req?.url !== '/'
    ) {
      return true
    }

    const authRoutes = Object.values(routes)
      .filter(route => route.auth)
      .map(route => route.component)

    if (!isConnected && authRoutes.includes(pathname)) {
      log('Auth: redirect to login', {
        current: pathname,
        dest: routes.about.component,
      })
      yield call(AuthSagas.redirectToPage, AuthSagas.res, routes.login)
      return true
    }

    if (
      isConnected &&
      user.accepts_disclaimer &&
      user.started_at &&
      user.sent_at &&
      pathname !== routes.thankyou.component
    ) {
      log('Auth: user has submit application')
      yield call(AuthSagas.redirectToPage, AuthSagas.res, routes.thankyou)
      return true
    }

    if (
      isConnected &&
      user.accepts_disclaimer &&
      user.started_at &&
      !user.sent_at &&
      ![
        routes.applicationCv.component,
        routes.applicationBackground.component,
        routes.applicationPersonal.component,
        routes.applicationPortfolio.component,
        routes.applicationSubmit.component,
      ].includes(pathname)
    ) {
      log('Auth: user has started start form but not completed yet')
      if (user.invalid_sections.includes(APPLICATIONS_SECTIONS.SECTION_CV)) {
        yield call(
          AuthSagas.redirectToPage,
          AuthSagas.res,
          routes.applicationCv
        )
        return true
      }
      if (
        user.invalid_sections.includes(APPLICATIONS_SECTIONS.SECTION_BACKGROUND)
      ) {
        yield call(
          AuthSagas.redirectToPage,
          AuthSagas.res,
          routes.applicationBackground
        )
        return true
      }
      if (
        user.invalid_sections.includes(
          APPLICATIONS_SECTIONS.SECTION_PERSONAL_STATEMENT
        )
      ) {
        yield call(
          AuthSagas.redirectToPage,
          AuthSagas.res,
          routes.applicationPersonal
        )
        return true
      }
      if (
        user.invalid_sections.includes(APPLICATIONS_SECTIONS.SECTION_PORTFOLIO)
      ) {
        yield call(
          AuthSagas.redirectToPage,
          AuthSagas.res,
          routes.applicationPortfolio
        )
        return true
      }
      yield call(AuthSagas.redirectToPage, AuthSagas.res, routes.applicationCv)
      return true
    }

    if (
      isConnected &&
      user.accepts_disclaimer &&
      !user.started_at &&
      ![
        routes.about.component,
        routes.start.component,
        routes.submitSchool.component,
      ].includes(pathname)
    ) {
      log(
        'Auth: user is logged in - has accepted disclaimer - go to about page'
      )
      yield call(AuthSagas.redirectToPage, AuthSagas.res, routes.about)
      return true
    }

    if (
      isConnected &&
      !user.accepts_disclaimer &&
      pathname !== routes.disclaimer.component
    ) {
      log(
        'Auth: user is logged in but need to accept disclaimer - then redirect to disclaimer'
      )
      yield call(AuthSagas.redirectToPage, AuthSagas.res, routes.disclaimer)
      return true
    }
  }

  static *updateCv({ payload }) {
    const stepKeys = payload.fields.map(e => e.name)
    const filteredPayload = {}

    for (let index = 0; index < stepKeys.length; index++) {
      const screenKey = stepKeys[index]

      filteredPayload[screenKey] = payload.cv[screenKey]
    }

    const cvPayload = CandidateTransformer(filteredPayload)
    const result = yield call(ApiSagas.query, services.updateCv, cvPayload)

    if (result?.data || !result.errors) {
      yield put(actions.setUser(result?.data?.updateCv))
    }
  }

  static *updateFiles({ payload }) {
    const user = yield select(AuthSelectors.user)

    const newFiles = Array.from(
      Array(Object.keys(payload).length / 2),
      (d, i) => ({
        description: payload[`description_${i}`],
        file: payload[`file_${i}`],
      })
    )

    const toDeleteFiles = newFiles.filter(
      val => val.file.filename === '' && typeof val.file.id !== 'undefined'
    )
    const toCreateFiles = newFiles
      .filter(
        val =>
          val.description !== '' &&
          val.file !== '' &&
          val.file.id &&
          val.file.filename
      )
      .filter(newFile => {
        const isAlreadyExists = user?.files?.find(
          file => file.id === newFile.file.id
        )

        if (isAlreadyExists) {
          return false
        }
        return true
      })

    const toEditFiles =
      toCreateFiles.length === 0
        ? newFiles.filter(newFile => {
            const initialValue = user?.files?.find(
              file => file.id === newFile.file.id
            )

            if (
              typeof newFile.file.id !== 'undefined' &&
              newFile.description !== '' &&
              newFile?.description !== initialValue?.description
            ) {
              return true
            }
          })
        : []

    if (
      toCreateFiles.length === 0 &&
      toDeleteFiles.length === 0 &&
      toEditFiles.length === 0
    ) {
      return
    }

    const result = yield call(ApiSagas.query, services.updateFiles, {
      create: toCreateFiles.map(elem => ({
        description: elem.description,
        path: elem.file.id,
        filename: elem.file.filename,
      })),
      update: toEditFiles.map(elem => ({
        id: elem.file.id,
        description: elem.description,
      })),
      delete: toDeleteFiles.map(elem => elem.file.id),
    })

    if (result?.data || !result.errors) {
      const mutatedUser = { ...user }

      mutatedUser.files = result?.data?.updateFiles?.files ?? user.files
      mutatedUser.is_complete =
        result?.data?.updateFiles?.is_complete ?? user.is_complete
      mutatedUser.invalid_sections =
        result?.data?.updateFiles?.invalid_sections ?? user.invalid_sections

      yield put(actions.setUser(mutatedUser))
    }
  }

  static *logout() {
    yield all([
      put(actions.setToken(null)),
      call(ApiSagas.query, services.logout),
    ])
    yield call(redirect, null, Router.getRouteUrl(routes.scholarship), 303)
  }

  static *loadMe() {
    const token = yield select(selectors.token)

    if (token) {
      const result = yield call(ApiSagas.query, services.me)

      if (result?.data || !result.errors) {
        yield put(actions.setUser(result?.data))
      }
    }
  }

  static *onToken({ payload }) {
    const token = payload

    log('Auth: onToken', !!token)
    if (token) {
      const result = yield call(ApiSagas.query, services.me)

      if (result?.data || !result.errors) {
        log('Auth: save new token')
        setAuthCookie(token)
        yield put(actions.setUser(result?.data))

        return
      }

      log('Auth: Remove token', result.errors)
      yield put(actions.setToken(null))
      return
    }

    log('Auth: Remove token, remove user')
    removeAuthCookie(AuthSagas.req)
    yield put(actions.setUser(null))
  }

  static *loop() {
    yield all([
      takeLatest(actions.setToken.getType(), AuthSagas.onToken),
      takeLatest(actions.logout.getType(), AuthSagas.logout),
      takeLatest(actions.loadMe.getType(), AuthSagas.loadMe),
      takeLatest(actions.updateCv.getType(), AuthSagas.updateCv),
      takeLatest(actions.updateFiles.getType(), AuthSagas.updateFiles),
    ])
  }
}
